function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_PRICES_REQUEST = "GET_PRICES_REQUEST";
export var GET_PRICES_SUCCESS = "GET_PRICES_SUCCESS";
export var GET_PRICES_FAILURE = "GET_PRICES_FAILURE";
export var loadPrices = function loadPrices(page, size, idFilterValue, investmentItemNameFilterValue, investmentItemNameFilterMode, startDateFilterValue, endDateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "instant,desc",
      projection: "withInvestmentItemAndCurrency",
      idFilterValue: idFilterValue,
      investmentItemNameFilterValue: investmentItemNameFilterValue,
      investmentItemNameFilterMode: investmentItemNameFilterMode,
      startDateFilterValue: startDateFilterValue,
      endDateFilterValue: endDateFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_PRICES_REQUEST, GET_PRICES_SUCCESS, GET_PRICES_FAILURE],
      endpoint: "prices/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_REQUEST = "GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_REQUEST";
export var GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_SUCCESS = "GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_SUCCESS";
export var GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_FAILURE = "GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_FAILURE";
export var loadPricesByInvestmentItemBrokersUrlFriendlyName = function loadPricesByInvestmentItemBrokersUrlFriendlyName(investmentItemBrokersUrlFriendlyName, page, size, idFilterValue, investmentItemNameFilterValue, investmentItemNameFilterMode, startDateFilterValue, endDateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "instant,desc",
      projection: "withInvestmentItemAndCurrency",
      investmentItemBrokersUrlFriendlyName: investmentItemBrokersUrlFriendlyName,
      idFilterValue: idFilterValue,
      investmentItemNameFilterValue: investmentItemNameFilterValue,
      investmentItemNameFilterMode: investmentItemNameFilterMode,
      startDateFilterValue: startDateFilterValue,
      endDateFilterValue: endDateFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_REQUEST, GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_SUCCESS, GET_PRICESBYINVESTMENTITEMBROKERSURLFRIENDLYNAME_FAILURE],
      endpoint: "prices/search/findAllByInvestmentItemBrokersUrlFriendlyName".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_PRICE_REQUEST = "POST_PRICE_REQUEST";
export var POST_PRICE_SUCCESS = "POST_PRICE_SUCCESS";
export var POST_PRICE_FAILURE = "POST_PRICE_FAILURE";
export var RESET_POST_PRICE = "RESET_POST_PRICE";
export var postPrice = function postPrice(price) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_PRICE_REQUEST, POST_PRICE_SUCCESS, POST_PRICE_FAILURE],
      endpoint: "prices",
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(price),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostPrice = function resetPostPrice() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_PRICE
    });
  };
};
export var DELETE_PRICES_REQUEST = "DELETE_PRICES_REQUEST";
export var DELETE_PRICES_SUCCESS = "DELETE_PRICES_SUCCESS";
export var DELETE_PRICES_FAILURE = "DELETE_PRICES_FAILURE";
export var RESET_DELETE_PRICES = "RESET_DELETE_PRICES";
export var deletePrices = function deletePrices(prices) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_PRICES_REQUEST, DELETE_PRICES_SUCCESS, DELETE_PRICES_FAILURE],
      endpoint: "prices",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(prices),
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTPRICESFROMCAFCI_REQUEST = "COLLECTPRICESFROMCAFCI_REQUEST";
export var COLLECTPRICESFROMCAFCI_SUCCESS = "COLLECTPRICESFROMCAFCI_SUCCESS";
export var COLLECTPRICESFROMCAFCI_FAILURE = "COLLECTPRICESFROMCAFCI_FAILURE";
export var RESET_COLLECTPRICESFROMCAFCI = "RESET_COLLECTPRICESFROMCAFCI";
export var collectPricesFromCafci = function collectPricesFromCafci(investmentItemIds, fromInstant, toInstant) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      investmentItemIds: investmentItemIds,
      fromInstant: fromInstant,
      toInstant: toInstant
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTPRICESFROMCAFCI_REQUEST, COLLECTPRICESFROMCAFCI_SUCCESS, COLLECTPRICESFROMCAFCI_FAILURE],
      endpoint: "prices/collectFromCafci".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTPRICESFROMCAFCI_REQUEST = "ABORTCOLLECTPRICESFROMCAFCI_REQUEST";
export var ABORTCOLLECTPRICESFROMCAFCI_SUCCESS = "ABORTCOLLECTPRICESFROMCAFCI_SUCCESS";
export var ABORTCOLLECTPRICESFROMCAFCI_FAILURE = "ABORTCOLLECTPRICESFROMCAFCI_FAILURE";
export var RESET_ABORTCOLLECTPRICESFROMCAFCI = "RESET_ABORTCOLLECTPRICESFROMCAFCI";
export var abortCollectPricesFromCafci = function abortCollectPricesFromCafci() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTPRICESFROMCAFCI_REQUEST, ABORTCOLLECTPRICESFROMCAFCI_SUCCESS, ABORTCOLLECTPRICESFROMCAFCI_FAILURE],
      endpoint: "prices/abortCollectFromCafci",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTPRICESFROMCAFCICONTEXT_REQUEST = "GET_COLLECTPRICESFROMCAFCICONTEXT_REQUEST";
export var GET_COLLECTPRICESFROMCAFCICONTEXT_SUCCESS = "GET_COLLECTPRICESFROMCAFCICONTEXT_SUCCESS";
export var GET_COLLECTPRICESFROMCAFCICONTEXT_FAILURE = "GET_COLLECTPRICESFROMCAFCICONTEXT_FAILURE";
export var RESET_GET_COLLECTPRICESFROMCAFCICONTEXT = "RESET_GET_COLLECTPRICESFROMCAFCICONTEXT";
export var loadCollectPricesFromCafciContext = function loadCollectPricesFromCafciContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTPRICESFROMCAFCICONTEXT_REQUEST, GET_COLLECTPRICESFROMCAFCICONTEXT_SUCCESS, GET_COLLECTPRICESFROMCAFCICONTEXT_FAILURE],
      endpoint: "prices/collectFromCafciContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var COLLECTPRICESFROMINVERTIRONLINE_REQUEST = "COLLECTPRICESFROMINVERTIRONLINE_REQUEST";
export var COLLECTPRICESFROMINVERTIRONLINE_SUCCESS = "COLLECTPRICESFROMINVERTIRONLINE_SUCCESS";
export var COLLECTPRICESFROMINVERTIRONLINE_FAILURE = "COLLECTPRICESFROMINVERTIRONLINE_FAILURE";
export var RESET_COLLECTPRICESFROMINVERTIRONLINE = "RESET_COLLECTPRICESFROMINVERTIRONLINE";
export var collectPricesFromInvertirOnline = function collectPricesFromInvertirOnline(investmentItemIds, fromInstant, toInstant) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      investmentItemIds: investmentItemIds,
      fromInstant: fromInstant,
      toInstant: toInstant
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [COLLECTPRICESFROMINVERTIRONLINE_REQUEST, COLLECTPRICESFROMINVERTIRONLINE_SUCCESS, COLLECTPRICESFROMINVERTIRONLINE_FAILURE],
      endpoint: "prices/collectFromInvertirOnline".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var ABORTCOLLECTPRICESFROMINVERTIRONLINE_REQUEST = "ABORTCOLLECTPRICESFROMINVERTIRONLINE_REQUEST";
export var ABORTCOLLECTPRICESFROMINVERTIRONLINE_SUCCESS = "ABORTCOLLECTPRICESFROMINVERTIRONLINE_SUCCESS";
export var ABORTCOLLECTPRICESFROMINVERTIRONLINE_FAILURE = "ABORTCOLLECTPRICESFROMINVERTIRONLINE_FAILURE";
export var RESET_ABORTCOLLECTPRICESFROMINVERTIRONLINE = "RESET_ABORTCOLLECTPRICESFROMINVERTIRONLINE";
export var abortCollectPricesFromInvertirOnline = function abortCollectPricesFromInvertirOnline() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [ABORTCOLLECTPRICESFROMINVERTIRONLINE_REQUEST, ABORTCOLLECTPRICESFROMINVERTIRONLINE_SUCCESS, ABORTCOLLECTPRICESFROMINVERTIRONLINE_FAILURE],
      endpoint: "prices/abortCollectFromInvertirOnline",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_REQUEST = "GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_REQUEST";
export var GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_SUCCESS = "GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_SUCCESS";
export var GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_FAILURE = "GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_FAILURE";
export var RESET_GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT = "RESET_GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT";
export var loadCollectPricesFromInvertirOnlineContext = function loadCollectPricesFromInvertirOnlineContext() {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_REQUEST, GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_SUCCESS, GET_COLLECTPRICESFROMINVERTIRONLINECONTEXT_FAILURE],
      endpoint: "prices/collectFromInvertirOnlineContext",
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEEN = "GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";

// Fetches prices for a particular investment item and date time between from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchPricesByInvestmentItemIdAndInstantBetweenGroupByDateFormat = function fetchPricesByInvestmentItemIdAndInstantBetweenGroupByDateFormat(investmentItemId, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    size: 999,
    sort: "instant,desc",
    projection: "chart",
    investmentItemId: investmentItemId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    investmentItemId: investmentItemId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat
  }, CALL_API, {
    types: [GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_PRICESBYINVESTMENTITEMIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "prices/search/findByInvestmentItem_IdAndInstantBetweenGroupByDateFormat".concat(queryString)
  });
};

// Fetches  prices for a particular investment item and date time between unless it is cached.
// Relies on Redux Thunk middleware.
export var loadPricesByInvestmentItemIdAndInstantBetweenGroupByDateFormat = function loadPricesByInvestmentItemIdAndInstantBetweenGroupByDateFormat(investmentItemId, fromInstant, toInstant, dateFormat, timeZoneName) {
  return function (dispatch, getState) {
    var key = [investmentItemId, fromInstant, toInstant, dateFormat].join("-");
    var prices = getState().entities.prices.byInvestmentItemIdAndInstantBetweenGroupByDateFormat[key];
    if (prices) return null;
    return dispatch(fetchPricesByInvestmentItemIdAndInstantBetweenGroupByDateFormat(investmentItemId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};
export var GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST = "GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST";
export var GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS = "GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS";
export var GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE = "GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";
export var RESET_GET_PRICESBYCURRENCYIDANDINVESTMENTITEMIDANDINSTANTBETWEEN = "GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE";

// Fetches prices for a particular currency, investment item, and date time between from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchPricesByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat = function fetchPricesByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat(investmentItemId, currencyId, brokerId, fromInstant, toInstant, dateFormat, timeZoneName) {
  var queryString = urlSearchParams({
    projection: "chart",
    investmentItemId: investmentItemId,
    currencyId: currencyId,
    brokerId: brokerId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  });
  return _defineProperty({
    investmentItemId: investmentItemId,
    currencyId: currencyId,
    brokerId: brokerId,
    fromInstant: fromInstant,
    toInstant: toInstant,
    dateFormat: dateFormat,
    timeZoneName: timeZoneName
  }, CALL_API, {
    types: [GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, GET_PRICESBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE],
    endpoint: "prices/search/findByInvestmentItem_IdAndCurrency_IdAndBroker_IdAndInstantBetweenGroupByDateFormatOrderByInstantDesc".concat(queryString)
  });
};

// Fetches prices for a particular currency, investment item, and date time between unless it is cached.
// Relies on Redux Thunk middleware.
export var loadPricesByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat = function loadPricesByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat(investmentItemId, currencyId, brokerId, fromInstant, toInstant, dateFormat, timeZoneName) {
  return function (dispatch, getState) {
    var key = [investmentItemId, currencyId, brokerId, fromInstant, toInstant, dateFormat, timeZoneName].join("-");
    var prices = getState().entities.prices.byInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat[key];
    if (prices) return null;
    return dispatch(fetchPricesByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat(investmentItemId, currencyId, brokerId, fromInstant, toInstant, dateFormat, timeZoneName));
  };
};
export var GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_REQUEST = "GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_REQUEST";
export var GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_SUCCESS = "GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_SUCCESS";
export var GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_FAILURE = "GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_FAILURE";
export var RESET_GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC = "RESET_GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC";

// Fetches latest price for a particular currency and investment item unless it is cached.
// Relies on Redux Thunk middleware.
export var loadFirstPriceByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc = function loadFirstPriceByInvestmentItemIdAndCurrencyIdAndBrokerIdOrderByInstantDesc(investmentItemId, currencyId, brokerId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "chart",
      investmentItemId: investmentItemId,
      currencyId: currencyId,
      brokerId: brokerId
    });
    return dispatch(_defineProperty({
      investmentItemId: investmentItemId,
      currencyId: currencyId,
      brokerId: brokerId
    }, CALL_API, {
      types: [GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_REQUEST, GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_SUCCESS, GET_FIRSTPRICEBYINVESTMENTITEMIDANDCURRENCYIDBROKERIDORDERBYINSTANTDESC_FAILURE],
      endpoint: "prices/search/findFirstByBroker_IdAndCurrency_IdAndInvestmentItem_IdOrderByInstantDesc".concat(queryString)
    }));
  };
};
export var GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_REQUEST = "GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_REQUEST";
export var GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_SUCCESS = "GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_SUCCESS";
export var GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_FAILURE = "GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_FAILURE";
export var RESET_GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC = "RESET_GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC";

// Fetches latest price for a particular variantInvestmentItem unless it is cached.
// Relies on Redux Thunk middleware.
export var loadFirstPriceByVariantInvestmentItemIdOrderByInstantDesc = function loadFirstPriceByVariantInvestmentItemIdOrderByInstantDesc(variantInvestmentItemId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "chart",
      variantInvestmentItemId: variantInvestmentItemId
    });
    return dispatch(_defineProperty({
      variantInvestmentItemId: variantInvestmentItemId
    }, CALL_API, {
      types: [GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_REQUEST, GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_SUCCESS, GET_FIRSTPRICEBYVARIANTINVESTMENTITEMIDORDERBYINSTANTDESC_FAILURE],
      endpoint: "prices/search/findFirstByVariantInvestmentItemIdOrderByInstantDesc".concat(queryString)
    }));
  };
};
export var PUSH_PRICE = "PUSH_PRICE";
export var pushPrice = function pushPrice(price) {
  return {
    price: price,
    type: PUSH_PRICE
  };
};