function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_GOALS_REQUEST = "GET_GOALS_REQUEST";
export var GET_GOALS_SUCCESS = "GET_GOALS_SUCCESS";
export var GET_GOALS_FAILURE = "GET_GOALS_FAILURE";
export var loadGoals = function loadGoals(page, size, idFilterValue, userIdFilterValue, startDueDateFilterValue, endDueDateFilterValue, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "updatedAt,desc",
      projection: "withGoalClassAndFundFractions",
      idFilterValue: idFilterValue,
      userIdFilterValue: userIdFilterValue,
      startDueDateFilterValue: startDueDateFilterValue,
      endDueDateFilterValue: endDueDateFilterValue,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALS_REQUEST, GET_GOALS_SUCCESS, GET_GOALS_FAILURE],
      endpoint: "goals/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_REQUEST = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_REQUEST";
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_SUCCESS = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_SUCCESS";
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_FAILURE = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID = "RESET_GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingOrTypesNameContainingAndMembersUserId = function loadGoalsByNameContainingOrTypesNameContainingAndMembersUserId(page, size, name, typesName, membersUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "withIdAndEmail",
      name: name,
      typesName: typesName,
      membersUserId: membersUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_REQUEST, GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_SUCCESS, GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERID_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingOrTypesNameContainingAndMembersUserId".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_REQUEST = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_REQUEST";
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_SUCCESS = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_SUCCESS";
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_FAILURE = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID = "RESET_GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID";

// Fetches goals by name or type name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingOrTypesNameContainingAndMembersUserFolloweesUserId = function loadGoalsByNameContainingOrTypesNameContainingAndMembersUserFolloweesUserId(page, size, name, typesName, membersUserFolloweesUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "withPictureAndMembers",
      name: name,
      typesName: typesName,
      membersUserFolloweesUserId: membersUserFolloweesUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_REQUEST, GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_SUCCESS, GET_GOALSBYNAMECONTAININGORTYPENAMECONTAININGANDMEMBERSUSERFOLLOWEESUSERID_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingOrTypesNameContainingAndMembersUserFolloweesUserId".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_REQUEST = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_REQUEST";
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_SUCCESS = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_SUCCESS";
export var GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_FAILURE = "GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING = "RESET_GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING";

// Fetches goals by name or type name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingOrTypesNameContaining = function loadGoalsByNameContainingOrTypesNameContaining(page, size, name, typesName) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "withIdAndEmail",
      name: name,
      typesName: typesName
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_REQUEST, GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_SUCCESS, GET_GOALSBYNAMECONTAININGORTYPENAMECONTAINING_FAILURE],
      endpoint: "goals/search/findByNameContainingOrTypesNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALBYID_REQUEST = "GET_GOALBYID_REQUEST";
export var GET_GOALBYID_SUCCESS = "GET_GOALBYID_SUCCESS";
export var GET_GOALBYID_FAILURE = "GET_GOALBYID_FAILURE";
export var RESET_GET_GOALBYID = "RESET_GET_GOALBYID";

// Fetches a particular goal from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
export var fetchGoalById = function fetchGoalById(id) {
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_GOALBYID_REQUEST, GET_GOALBYID_SUCCESS, GET_GOALBYID_FAILURE],
    endpoint: "goals/".concat(id),
    init: {
      credentials: "same-origin"
    }
  });
};
export var POST_GOAL_REQUEST = "POST_GOAL_REQUEST";
export var POST_GOAL_SUCCESS = "POST_GOAL_SUCCESS";
export var POST_GOAL_FAILURE = "POST_GOAL_FAILURE";
export var RESET_POST_GOAL = "RESET_POST_GOAL";
export var postGoal = function postGoal(goal) {
  return function (dispatch, getState) {
    var endpoint = "goals";
    if (goal.id) endpoint += "/".concat(goal.id);
    return dispatch(_defineProperty({
      userId: goal.createdByEmail,
      sgoalId: goal.id
    }, CALL_API, {
      types: [POST_GOAL_REQUEST, POST_GOAL_SUCCESS, POST_GOAL_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(goal),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostGoal = function resetPostGoal() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_GOAL
    });
  };
};
export var POST_GOALDUEDATE_REQUEST = "POST_GOALDUEDATE_REQUEST";
export var POST_GOALDUEDATE_SUCCESS = "POST_GOALDUEDATE_SUCCESS";
export var POST_GOALDUEDATE_FAILURE = "POST_GOALDUEDATE_FAILURE";
export var RESET_POST_GOALDUEDATE = "RESET_POST_GOALDUEDATE";
export var postGoalDueDate = function postGoalDueDate(goal, dueDate) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("dueDate", dueDate);
    return dispatch(_defineProperty({
      goal: goal,
      dueDate: dueDate
    }, CALL_API, {
      types: [POST_GOALDUEDATE_REQUEST, POST_GOALDUEDATE_SUCCESS, POST_GOALDUEDATE_FAILURE],
      endpoint: "goals/".concat(goal.id, "/dueDate"),
      init: {
        method: "POST",
        body: body,
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_GOALPICTURE_REQUEST = "POST_GOALPICTURE_REQUEST";
export var POST_GOALPICTURE_SUCCESS = "POST_GOALPICTURE_SUCCESS";
export var POST_GOALPICTURE_FAILURE = "POST_GOALPICTURE_FAILURE";
export var RESET_POST_GOALPICTURE = "RESET_POST_GOALPICTURE";
export var postGoalPicture = function postGoalPicture(goal, picture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goal: goal,
      picture: picture
    }, CALL_API, {
      types: [POST_GOALPICTURE_REQUEST, POST_GOALPICTURE_SUCCESS, POST_GOALPICTURE_FAILURE],
      endpoint: "goals/".concat(goal.id, "/picture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(picture),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_GOALBACKGROUNDPICTURE_REQUEST = "POST_GOALBACKGROUNDPICTURE_REQUEST";
export var POST_GOALBACKGROUNDPICTURE_SUCCESS = "POST_GOALBACKGROUNDPICTURE_SUCCESS";
export var POST_GOALBACKGROUNDPICTURE_FAILURE = "POST_GOALBACKGROUNDPICTURE_FAILURE";
export var RESET_POST_GOALBACKGROUNDPICTURE = "RESET_POST_GOALBACKGROUNDPICTURE";
export var postGoalBackgroundPicture = function postGoalBackgroundPicture(goal, picture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goal: goal,
      picture: picture
    }, CALL_API, {
      types: [POST_GOALBACKGROUNDPICTURE_REQUEST, POST_GOALBACKGROUNDPICTURE_SUCCESS, POST_GOALBACKGROUNDPICTURE_FAILURE],
      endpoint: "goals/".concat(goal.id, "/backgroundPicture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(picture),
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_GOALAMOUNTS_REQUEST = "POST_GOALAMOUNTS_REQUEST";
export var POST_GOALAMOUNTS_SUCCESS = "POST_GOALAMOUNTS_SUCCESS";
export var POST_GOALAMOUNTS_FAILURE = "POST_GOALAMOUNTS_FAILURE";
export var RESET_POST_GOALAMOUNTS = "RESET_POST_GOALAMOUNTS";
export var postGoalAmounts = function postGoalAmounts(goal, amounts) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goal: goal,
      amounts: amounts
    }, CALL_API, {
      types: [POST_GOALAMOUNTS_REQUEST, POST_GOALAMOUNTS_SUCCESS, POST_GOALAMOUNTS_FAILURE],
      endpoint: "goals/".concat(goal.id, "/amounts"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(amounts),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostGoalAmounts = function resetPostGoalAmounts() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_GOALAMOUNTS
    });
  };
};
export var POST_GOALMEMBERS_REQUEST = "POST_GOALMEMBERS_REQUEST";
export var POST_GOALMEMBERS_SUCCESS = "POST_GOALMEMBERS_SUCCESS";
export var POST_GOALMEMBERS_FAILURE = "POST_GOALMEMBERS_FAILURE";
export var RESET_POST_GOALMEMBERS = "RESET_POST_GOALMEMBERS";
export var postGoalMembers = function postGoalMembers(goal, members) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goal: goal,
      members: members
    }, CALL_API, {
      types: [POST_GOALMEMBERS_REQUEST, POST_GOALMEMBERS_SUCCESS, POST_GOALMEMBERS_FAILURE],
      endpoint: "goals/".concat(goal.id, "/members"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(members),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostGoalMembers = function resetPostGoalMembers() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_GOALMEMBERS
    });
  };
};
export var POST_GOALEXCLUSIONPREFERENCES_REQUEST = "POST_GOALEXCLUSIONPREFERENCES_REQUEST";
export var POST_GOALEXCLUSIONPREFERENCES_SUCCESS = "POST_GOALEXCLUSIONPREFERENCES_SUCCESS";
export var POST_GOALEXCLUSIONPREFERENCES_FAILURE = "POST_GOALEXCLUSIONPREFERENCES_FAILURE";
export var RESET_POST_GOALEXCLUSIONPREFERENCES = "RESET_POST_GOALEXCLUSIONPREFERENCES";
export var postGoalExclusionPreferences = function postGoalExclusionPreferences(goal, exclusionPreferences) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goal: goal,
      exclusionPreferences: exclusionPreferences
    }, CALL_API, {
      types: [POST_GOALEXCLUSIONPREFERENCES_REQUEST, POST_GOALEXCLUSIONPREFERENCES_SUCCESS, POST_GOALEXCLUSIONPREFERENCES_FAILURE],
      endpoint: "goals/".concat(goal.id, "/exclusionPreferences"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(exclusionPreferences),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostGoalExclusionPreferences = function resetPostGoalExclusionPreferences(id) {
  return function (dispatch, getState) {
    return dispatch({
      id: id,
      type: RESET_POST_GOALEXCLUSIONPREFERENCES
    });
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsCurrencyId, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsCurrencyId: operationsCurrencyId,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_Currency_IdAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsCurrencyId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsCurrencyId: operationsCurrencyId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_Currency_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsBrokerIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsCurrencyIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsCurrencyIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalsByNameContainingAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadGoalsByNameContainingAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_GOALSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "goals/search/findDistinctByNameContainingAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};