function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTITEMBYID_REQUEST = "GET_INVESTMENTITEMBYID_REQUEST";
export var GET_INVESTMENTITEMBYID_SUCCESS = "GET_INVESTMENTITEMBYID_SUCCESS";
export var GET_INVESTMENTITEMBYID_FAILURE = "GET_INVESTMENTITEMBYID_FAILURE";
export var RESET_GET_INVESTMENTITEMBYID = "RESET_GET_INVESTMENTITEMBYID";

// Fetches a particular investmentItem from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvsetmentItemById = function fetchInvsetmentItemById(id) {
  var queryString = urlSearchParams({
    projection: "full"
  });
  return _defineProperty({
    id: id
  }, CALL_API, {
    types: [GET_INVESTMENTITEMBYID_REQUEST, GET_INVESTMENTITEMBYID_SUCCESS, GET_INVESTMENTITEMBYID_FAILURE],
    endpoint: "investmentItems/".concat(id).concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches a particular investmentItem by email from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadInvestmentItemById = function loadInvestmentItemById(id, forceUpdate) {
  return function (dispatch, getState) {
    // const investmentItem = getState().entities.investmentItems.byId[id];
    // if (investmentItem && !forceUpdate) return null;
    // const call = getState().apiCalls.loadInvestmentItemById[id];
    // if (call && call.isFetching) return null;

    return dispatch(fetchInvsetmentItemById(id));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID";

// Fetches investment items by name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndVariantsCurrencyId = function loadInvestmentItemsByNameContainingAndVariantsCurrencyId(page, size, name, variantsCurrencyId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      variantsCurrencyId: variantsCurrencyId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDVARIANTSCURRENCYID_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndVariants_Currency_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAINING_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAINING_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAINING_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAINING_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAINING_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAINING_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAINING = "RESET_GET_INVESTMENTITEMSBYNAMECONTAINING";

// Fetches investment items by name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingOrSymbolStartingWith = function loadInvestmentItemsByNameContainingOrSymbolStartingWith(page, size, name, symbol) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      symbol: symbol
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAINING_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAINING_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAINING_FAILURE],
      endpoint: "investmentItems/search/findByNameContainingOrSymbolStartingWith".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_REQUEST = "GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_REQUEST";
export var GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_SUCCESS = "GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_SUCCESS";
export var GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_FAILURE = "GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING = "RESET_GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING";

// Fetches investment items by name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByAvailabilitiesBrokerIdAndNameContaining = function loadInvestmentItemsByAvailabilitiesBrokerIdAndNameContaining(page, size, availabilitiesBrokerId, name) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      availabilitiesBrokerId: availabilitiesBrokerId,
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_REQUEST, GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_SUCCESS, GET_INVESTMENTITEMSBYAVAILABILITIESBROKERIDANDNAMECONTAINING_FAILURE],
      endpoint: "investmentItems/search/findByAvailabilitiesBrokerIdAndNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMS_REQUEST = "GET_INVESTMENTITEMS_REQUEST";
export var GET_INVESTMENTITEMS_SUCCESS = "GET_INVESTMENTITEMS_SUCCESS";
export var GET_INVESTMENTITEMS_FAILURE = "GET_INVESTMENTITEMS_FAILURE";
export var loadInvestmentItems = function loadInvestmentItems(page, size, idFilterValue, nameFilterValue, startDateFilterValue, endDateFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      idFilterValue: idFilterValue,
      nameFilterValue: nameFilterValue,
      startDateFilterValue: startDateFilterValue,
      endDateFilterValue: endDateFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMS_REQUEST, GET_INVESTMENTITEMS_SUCCESS, GET_INVESTMENTITEMS_FAILURE],
      endpoint: "investmentItems/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_REQUEST = "GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_REQUEST";
export var GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_SUCCESS = "GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_SUCCESS";
export var GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_FAILURE = "GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID = "RESET_GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID";

// Fetches investmentItems by broker from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvestmentItemsByAvailabilitiesBrokerId = function fetchInvestmentItemsByAvailabilitiesBrokerId(availabilitiesBrokerId) {
  return _defineProperty({
    availabilitiesBrokerId: availabilitiesBrokerId
  }, CALL_API, {
    types: [GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_REQUEST, GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_SUCCESS, GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_FAILURE],
    endpoint: "investmentItems/search/findByAvailabilitiesBrokerId?availabilitiesBrokerId=".concat(availabilitiesBrokerId),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches investmentItems by broker unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByAvailabilitiesBrokerId = function loadInvestmentItemsByAvailabilitiesBrokerId(availabilitiesBrokerId) {
  return function (dispatch, getState) {
    var investmentItems = getState().entities.investmentItems.byAvailabilitiesBrokerId[availabilitiesBrokerId];
    if (investmentItems) return null;
    return dispatch(fetchInvestmentItemsByAvailabilitiesBrokerId(availabilitiesBrokerId));
  };
};
export var GET_INVESTMENTITEMBYURLFRIENDLYNAME_REQUEST = "GET_INVESTMENTITEMBYURLFRIENDLYNAME_REQUEST";
export var GET_INVESTMENTITEMBYURLFRIENDLYNAME_SUCCESS = "GET_INVESTMENTITEMBYURLFRIENDLYNAME_SUCCESS";
export var GET_INVESTMENTITEMBYURLFRIENDLYNAME_FAILURE = "GET_INVESTMENTITEMBYURLFRIENDLYNAME_FAILURE";
export var RESET_GET_INVESTMENTITEMBYURLFRIENDLYNAME = "RESET_GET_INVESTMENTITEMBYURLFRIENDLYNAME";

// Fetches a particular investment item from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchInvestmentItemByUrlFriendlyName = function fetchInvestmentItemByUrlFriendlyName(urlFriendlyName) {
  return _defineProperty({
    urlFriendlyName: urlFriendlyName
  }, CALL_API, {
    types: [GET_INVESTMENTITEMBYURLFRIENDLYNAME_REQUEST, GET_INVESTMENTITEMBYURLFRIENDLYNAME_SUCCESS, GET_INVESTMENTITEMBYURLFRIENDLYNAME_FAILURE],
    endpoint: "investmentItems/search/findByUrlFriendlyName?urlFriendlyName=".concat(urlFriendlyName, "&projection=full"),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches a particular investment item by id from Front API unless it is cached.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemByUrlFriendlyName = function loadInvestmentItemByUrlFriendlyName(urlFriendlyName, forceUpdate) {
  return function (dispatch, getState) {
    var investmentItem = getState().entities.investmentItems.byUrlFriendlyName[urlFriendlyName];
    if (investmentItem && !forceUpdate) return null;
    return dispatch(fetchInvestmentItemByUrlFriendlyName(urlFriendlyName));
  };
};
export var POST_INVESTMENTITEM_REQUEST = "POST_INVESTMENTITEM_REQUEST";
export var POST_INVESTMENTITEM_SUCCESS = "POST_INVESTMENTITEM_SUCCESS";
export var POST_INVESTMENTITEM_FAILURE = "POST_INVESTMENTITEM_FAILURE";
export var RESET_POST_INVESTMENTITEM = "RESET_POST_INVESTMENTITEM";
export var postInvestmentItem = function postInvestmentItem(investmentItem) {
  return function (dispatch, getState) {
    var endpoint = "investmentItems";
    if (investmentItem.id) endpoint += "/".concat(investmentItem.id);
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_INVESTMENTITEM_REQUEST, POST_INVESTMENTITEM_SUCCESS, POST_INVESTMENTITEM_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(investmentItem),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostInvestmentItem = function resetPostInvestmentItem() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_INVESTMENTITEM
    });
  };
};
export var POST_INVESTMENTITEMDESCRIPTION_REQUEST = "POST_INVESTMENTITEMDESCRIPTION_REQUEST";
export var POST_INVESTMENTITEMDESCRIPTION_SUCCESS = "POST_INVESTMENTITEMDESCRIPTION_SUCCESS";
export var POST_INVESTMENTITEMDESCRIPTION_FAILURE = "POST_INVESTMENTITEMDESCRIPTION_FAILURE";
export var RESET_POST_INVESTMENTITEMDESCRIPTION = "RESET_POST_INVESTMENTITEMDESCRIPTION";
export var postInvestmentItemDescription = function postInvestmentItemDescription(investmentItem, description) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("description", description);
    return dispatch(_defineProperty({
      investmentItem: investmentItem,
      description: description
    }, CALL_API, {
      types: [POST_INVESTMENTITEMDESCRIPTION_REQUEST, POST_INVESTMENTITEMDESCRIPTION_SUCCESS, POST_INVESTMENTITEMDESCRIPTION_FAILURE],
      endpoint: "investmentItems/".concat(investmentItem.id, "/description"),
      init: {
        method: "POST",
        body: body,
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostInvestmentItemDescription = function resetPostInvestmentItemDescription() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_INVESTMENTITEMDESCRIPTION
    });
  };
};
export var POST_INVESTMENTITEMPICTURE_REQUEST = "POST_INVESTMENTITEMPICTURE_REQUEST";
export var POST_INVESTMENTITEMPICTURE_SUCCESS = "POST_INVESTMENTITEMPICTURE_SUCCESS";
export var POST_INVESTMENTITEMPICTURE_FAILURE = "POST_INVESTMENTITEMPICTURE_FAILURE";
export var RESET_POST_INVESTMENTITEMPICTURE = "RESET_POST_INVESTMENTITEMPICTURE";
export var postInvestmentItemPicture = function postInvestmentItemPicture(investmentItem, picture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      investmentItem: investmentItem,
      picture: picture
    }, CALL_API, {
      types: [POST_INVESTMENTITEMPICTURE_REQUEST, POST_INVESTMENTITEMPICTURE_SUCCESS, POST_INVESTMENTITEMPICTURE_FAILURE],
      endpoint: "investmentItems/".concat(investmentItem.id, "/picture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(picture),
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsCurrencyId, operationsGoalId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsCurrencyId: operationsCurrencyId,
      operationsGoalId: operationsGoalId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_Currency_IdAndOperations_Goal_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsCurrencyId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsCurrencyId: operationsCurrencyId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_Currency_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsGoalId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsGoalId: operationsGoalId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_Goal_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsBrokerIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsBrokerId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsBrokerId: operationsBrokerId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Broker_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsGoalId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsGoalId: operationsGoalId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_Goal_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsGoalId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsGoalId: operationsGoalId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_Goal_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadInvestmentItemsByNameContainingAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_REQUEST = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_REQUEST";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_SUCCESS = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_SUCCESS";
export var GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_FAILURE = "GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_FAILURE";
export var RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID = "RESET_GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID";

// Fetches goals by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadInvestmentItemsByNameContainingAndOperationsUserId = function loadInvestmentItemsByNameContainingAndOperationsUserId(page, size, name, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_REQUEST, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_SUCCESS, GET_INVESTMENTITEMSBYNAMECONTAININGANDOPERATIONUSERID_FAILURE],
      endpoint: "investmentItems/search/findDistinctByNameContainingAndOperations_User_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};