function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var bankAccountEntities = function bankAccountEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _defineProperty({
    byUserId: {},
    byBrokerIdAndCurrencyId: {},
    byCurrencyIdAndUserId: {},
    byBrokerId: {}
  }, "byUserId", {});
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_BANKACCOUNTSBYUSERID_SUCCESS:
      var byUserId = _objectSpread({}, state.byUserId);
      byUserId[action.userId] = action.response._embedded.bankAccounts;
      return Object.assign({}, state, {
        byUserId: byUserId
      });
    case ActionTypes.GET_CARTVIEWBYUSERIDANDOPERATIONSTATE_SUCCESS:
      var byBrokerIdAndCurrencyId = action.response.bankAccountByBrokerIdAndCurrencyId;
      var byCurrencyIdAndUserId = action.response.bankAccountByCurrencyIdAndUserId;
      return merge({}, state, {
        byBrokerIdAndCurrencyId: byBrokerIdAndCurrencyId,
        byCurrencyIdAndUserId: byCurrencyIdAndUserId
      });
    case ActionTypes.GET_PENDINGDEPOSITSVIEWBYUSERID_SUCCESS:
      var byBrokerId = action.response.bankAccountByBrokerId;
      var byUserId = action.response.bankAccountByUserId;
      return merge({}, state, {
        byBrokerId: byBrokerId,
        byUserId: byUserId
      });
    case ActionTypes.POST_USERBANKACCOUNTS_SUCCESS:
      var byUserEmail = _objectSpread({}, state.byUserEmail);
      var bankAccounts = action.response._embedded;
      byUserEmail[bankAccounts[0].user.email] = bankAccounts;
      return Object.assign({}, state, {
        byUserEmail: byUserEmail
      });
    default:
      return state;
  }
};
export default bankAccountEntities;