function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var postEntities = function postEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byParentId: {},
    byParentIdAndUserId: {},
    byEventIdAndUserId: {},
    byUserIdNotAndMediasNotEmptyOrUrlNotNull: {},
    byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: {},
    byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: {},
    byEventIdAndAuthUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var byParentIdAndUserId = _objectSpread({}, state.byParentIdAndUserId);
      var byParentId = _objectSpread({}, state.byParentId);
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byEventIdAndAuthUserId = _objectSpread({}, state.byEventIdAndAuthUserId);
      var byEventIdAndUserId = _objectSpread({}, state.byEventIdAndUserId);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        eventListItem.postListItemByPostId && Object.values(eventListItem.postListItemByPostId).forEach(function (postListItem) {
          merge(byId, postListItem.postById);
          merge(byParentIdAndUserId, postListItem.postsByPostIdAndUserId);
          postListItem.postsByParentId && Object.keys(postListItem.postsByParentId).forEach(function (k) {
            return postListItem.postsByParentId[k].reverse();
          });
          merge(byParentId, postListItem.postsByParentId);
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        });
        eventListItem.postsByEventIdAndAuthUserId && Object.keys(eventListItem.postsByEventIdAndAuthUserId).forEach(function (k) {
          return eventListItem.postsByEventIdAndAuthUserId[k].reverse();
        });
        merge(byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, eventListItem.postsByEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        merge(byEventIdAndAuthUserId, eventListItem.postsByEventIdAndAuthUserId);
        merge(byEventIdAndUserId, eventListItem.postsByEventIdAndUserId);
      });
      return Object.assign({}, state, {
        byId: byId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byParentId: byParentId,
        byParentIdAndUserId: byParentIdAndUserId,
        byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byEventIdAndAuthUserId: byEventIdAndAuthUserId,
        byEventIdAndUserId: byEventIdAndUserId
      });
    case ActionTypes.GET_HASHTAGVIEWBYHASHTAGTEXTANDAUTHUSERID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byParentId = _objectSpread({}, state.byParentId);
      var byParentIdAndUserId = _objectSpread({}, state.byParentIdAndUserId);
      var hashtagId = action.response.hashtagId;
      var key = [hashtagId, action.authUserId].join("-");
      action.response.hashtagMentionListItemsByHashtagMentionHashtagIdAndAuthUserId[key].forEach(function (mentionListItem) {
        return mentionListItem.postListItemByPostId && Object.values(mentionListItem.postListItemByPostId).forEach(function (postListItem) {
          merge(byId, postListItem.postById);
          postListItem.postsByParentId && Object.keys(postListItem.postsByParentId).forEach(function (k) {
            return postListItem.postsByParentId[k].reverse();
          });
          merge(byParentIdAndUserId, postListItem.postsByPostIdAndUserId);
          merge(byParentId, postListItem.postsByParentId);
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        });
      });
      return merge({}, state, {
        byId: byId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byParentId: byParentId,
        byParentIdAndUserId: byParentIdAndUserId
      });
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var byParentIdAndUserId = _objectSpread({}, state.byParentIdAndUserId);
      var byParentId = _objectSpread({}, state.byParentId);
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byEventIdAndAuthUserId = _objectSpread({}, state.byEventIdAndAuthUserId);
      var byEventIdAndUserId = _objectSpread({}, state.byEventIdAndUserId);
      action.response.forEach(function (eventListItem) {
        eventListItem.postListItemByPostId && Object.values(eventListItem.postListItemByPostId).forEach(function (postListItem) {
          merge(byId, postListItem.postById);
          merge(byParentIdAndUserId, postListItem.postsByPostIdAndUserId);
          postListItem.postsByParentId && Object.keys(postListItem.postsByParentId).forEach(function (k) {
            return postListItem.postsByParentId[k].reverse();
          });
          merge(byParentId, postListItem.postsByParentId);
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        });
        eventListItem.postsByEventIdAndAuthUserId && Object.keys(eventListItem.postsByEventIdAndAuthUserId).forEach(function (k) {
          return eventListItem.postsByEventIdAndAuthUserId[k].reverse();
        });
        merge(byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, eventListItem.postsByEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        merge(byEventIdAndAuthUserId, eventListItem.postsByEventIdAndAuthUserId);
        merge(byEventIdAndUserId, eventListItem.postsByEventIdAndUserId);
      });
      return Object.assign({}, state, {
        byId: byId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byParentId: byParentId,
        byParentIdAndUserId: byParentIdAndUserId,
        byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byEventIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byEventIdAndAuthUserId: byEventIdAndAuthUserId,
        byEventIdAndUserId: byEventIdAndUserId
      });
    case ActionTypes.GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID_SUCCESS:
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byParentId = _objectSpread({}, state.byParentId);
      var byParentIdAndUserId = _objectSpread({}, state.byParentIdAndUserId);
      action.response.forEach(function (mentionListItem) {
        mentionListItem.postListItemByPostId && Object.values(mentionListItem.postListItemByPostId).forEach(function (postListItem) {
          merge(byId, postListItem.postById);
          postListItem.postsByParentId && Object.keys(postListItem.postsByParentId).forEach(function (k) {
            return postListItem.postsByParentId[k].reverse();
          });
          merge(byParentIdAndUserId, postListItem.postsByPostIdAndUserId);
          merge(byParentId, postListItem.postsByParentId);
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        });
      });
      return Object.assign({}, state, {
        byId: byId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byParentId: byParentId,
        byParentIdAndUserId: byParentIdAndUserId
      });
    case ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byParentId = _objectSpread({}, state.byParentId);
      var byParentIdAndUserId = _objectSpread({}, state.byParentIdAndUserId);
      action.response.forEach(function (bookmarkListItem) {
        return bookmarkListItem.postListItemByPostBookmarksId && Object.values(bookmarkListItem.postListItemByPostBookmarksId).forEach(function (postListItem) {
          merge(byId, postListItem.postById);
          postListItem.postsByParentId && Object.keys(postListItem.postsByParentId).forEach(function (k) {
            return postListItem.postsByParentId[k].reverse();
          });
          merge(byParentIdAndUserId, postListItem.postsByPostIdAndUserId);
          merge(byParentId, postListItem.postsByParentId);
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        });
      });
      return Object.assign({}, state, {
        byId: byId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byParentId: byParentId,
        byParentIdAndUserId: byParentIdAndUserId
      });
    case ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULL_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byParentId = _objectSpread({}, state.byParentId);
      var byParentIdAndUserId = _objectSpread({}, state.byParentIdAndUserId);
      action.response.forEach(function (postListItem) {
        merge(byId, postListItem.postById);
        postListItem.postsByParentId && Object.keys(postListItem.postsByParentId).forEach(function (k) {
          return postListItem.postsByParentId[k].reverse();
        });
        merge(byParentIdAndUserId, postListItem.postsByPostIdAndUserId);
        merge(byParentId, postListItem.postsByParentId);
        merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      });
      return Object.assign({}, state, {
        byId: byId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byParentId: byParentId,
        byParentIdAndUserId: byParentIdAndUserId
      });
    case ActionTypes.GET_POSTSBYUSERIDNOTANDMEDIASNOTEMPTYORURLNOTNULL_SUCCESS:
      var byUserIdNotAndMediasNotEmptyOrUrlNotNull = _objectSpread({}, state.byUserIdNotAndMediasNotEmptyOrUrlNotNull);
      var key = action.userId;
      if (byUserIdNotAndMediasNotEmptyOrUrlNotNull[key] && action.sinceId !== undefined) byUserIdNotAndMediasNotEmptyOrUrlNotNull[key] = action.response.reverse().concat(byUserIdNotAndMediasNotEmptyOrUrlNotNull[key]);else if (byUserIdNotAndMediasNotEmptyOrUrlNotNull[key] && action.maxId !== undefined) byUserIdNotAndMediasNotEmptyOrUrlNotNull[key] = byUserIdNotAndMediasNotEmptyOrUrlNotNull[key].concat(action.response);else byUserIdNotAndMediasNotEmptyOrUrlNotNull[key] = action.response;
      return Object.assign({}, state, {
        byUserIdNotAndMediasNotEmptyOrUrlNotNull: byUserIdNotAndMediasNotEmptyOrUrlNotNull
      });
    case ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS:
      return merge({}, state, {
        byId: action.response.postById,
        byParentIdAndUserId: action.response.postsByPostIdAndUserId,
        byParentId: action.response.postsByParentId,
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: action.response.postsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull
      });
    case ActionTypes.GET_POSTSBYEVENTIDANDAUTHUSERID_SUCCESS:
      var byEventIdAndAuthUserId = _objectSpread({}, state.byEventIdAndAuthUserId);
      var key = [action.eventId, action.userId].join("-");
      if (byEventIdAndAuthUserId[key] && action.sinceId !== undefined) byEventIdAndAuthUserId[key] = byEventIdAndAuthUserId[key].concat(action.response);else if (byEventIdAndAuthUserId[key] && action.maxId !== undefined) byEventIdAndAuthUserId[key] = action.response.reverse().concat(byEventIdAndAuthUserId[key]);else byEventIdAndAuthUserId[key] = action.response.reverse();
      return Object.assign({}, state, {
        byEventIdAndAuthUserId: byEventIdAndAuthUserId
      });
    case ActionTypes.POST_POSTREACTION_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      if (!byId[action.postReaction.postId]) return state;
      byId[action.postReaction.postId].forr = action.response._embedded;
      byId[action.postReaction.postId].reactionsCount++;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.DELETE_POSTREACTION_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      if (!byId[action.postReaction.post.id]) return state;
      byId[action.postReaction.post.id].reactionsCount--;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_POSTS_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var embedded = action.response._embedded ? action.response._embedded.posts : [];
      embedded && embedded.forEach(function (post) {
        return byId[post.id] = post;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_POST_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      if (action.post.id) byId[action.post.id] = action.response._embedded;
      var parentId = action.post.parentId;
      var parent = byId[parentId];
      if (parent) {
        var parentCopy = _objectSpread({}, parent);
        if (!parentCopy.childrenCount) parentCopy.childrenCount = 0;
        parentCopy.childrenCount++;
        byId[parentId] = parentCopy;
      }
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.DELETE_POST_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      delete byId[action.post.id];
      if (action.post.parent) {
        var key = action.post.parent.id;
        var post = byId[key];
        var postCopy = _objectSpread({}, post);
        postCopy.childrenCount--;
        byId[key] = postCopy;
      }
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS:
      var key = action.response.principal && action.response.principal.id;
      var notificationListItems = action.response.notificationListItemsByUserId[key];
      if (!notificationListItems) return state;
      var byId = _objectSpread({}, state.byId);
      notificationListItems.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.postById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS:
    case ActionTypes.GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.postById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    default:
      return state;
  }
};
export default postEntities;