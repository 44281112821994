function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export var GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export var GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";
export var RESET_GET_CURRENCIES = "RESET_GET_CURRENCIES";

//Fetches all currencies from Front API.
//Relies on Redux Thunk middleware.
export var loadCurrencies = function loadCurrencies(page, size, idFilterValue, nameFilterValue, startDateFilterValue, endDateFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      idFilterValue: idFilterValue,
      nameFilterValue: nameFilterValue,
      startDateFilterValue: startDateFilterValue,
      endDateFilterValue: endDateFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIES_REQUEST, GET_CURRENCIES_SUCCESS, GET_CURRENCIES_FAILURE],
      endpoint: "currencies/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID = "RESET_GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID";

// Fetches currencies by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyPricesInvestmentItemIdAndCurrencyPricesBrokerId = function loadCurrenciesByNameContainingAndCurrencyPricesInvestmentItemIdAndCurrencyPricesBrokerId(page, size, name, currencyPricesInvestmentItemId, currencyPricesBrokerId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      currencyPricesInvestmentItemId: currencyPricesInvestmentItemId,
      currencyPricesBrokerId: currencyPricesBrokerId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMIDANDCURRENCYPRICESBROKERID_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyPrices_InvestmentItem_IdAndCurrencyPrices_Broker_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID = "RESET_GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID";

// Fetches currencies by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyPricesInvestmentItemId = function loadCurrenciesByNameContainingAndCurrencyPricesInvestmentItemId(page, size, name, currencyPricesInvestmentItemId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      currencyPricesInvestmentItemId: currencyPricesInvestmentItemId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESINVESTMENTITEMID_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyPrices_InvestmentItem_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID = "RESET_GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID";

// Fetches currencies by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyPricesBrokerId = function loadCurrenciesByNameContainingAndCurrencyPricesBrokerId(page, size, name, currencyPricesBrokerId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      currencyPricesBrokerId: currencyPricesBrokerId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDCURRENCYPRICESBROKERID_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyPrices_Broker_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAINING_REQUEST = "GET_CURRENCIESBYNAMECONTAINING_REQUEST";
export var GET_CURRENCIESBYNAMECONTAINING_SUCCESS = "GET_CURRENCIESBYNAMECONTAINING_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAINING_FAILURE = "GET_CURRENCIESBYNAMECONTAINING_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAINING = "RESET_GET_CURRENCIESBYNAMECONTAINING";

// Fetches currencies by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContaining = function loadCurrenciesByNameContaining(page, size, name) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAINING_REQUEST, GET_CURRENCIESBYNAMECONTAINING_SUCCESS, GET_CURRENCIESBYNAMECONTAINING_FAILURE],
      endpoint: "currencies/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_REQUEST = "GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_REQUEST";
export var GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_SUCCESS = "GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_SUCCESS";
export var GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_FAILURE = "GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_FAILURE";
export var RESET_GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID = "RESET_GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID";

// Fetches currencies from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId = function fetchCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId(currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId) {
  var queryString = urlSearchParams({
    currencyGoalUserBalancesGoalId: currencyGoalUserBalancesGoalId,
    currencyGoalUserBalancesUserId: currencyGoalUserBalancesUserId
  });
  return _defineProperty({
    currencyGoalUserBalancesGoalId: currencyGoalUserBalancesGoalId,
    currencyGoalUserBalancesUserId: currencyGoalUserBalancesUserId
  }, CALL_API, {
    types: [GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_REQUEST, GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_SUCCESS, GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_FAILURE],
    endpoint: "currencies/search/findDistinctByCurrencyGoalUserBalancesGoal_IdAndCurrencyGoalUserBalancesUserId".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches currencies by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId = function loadCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId(currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId, forceUpdate) {
  return function (dispatch, getState) {
    var key = [currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId].join("-");
    var currencies = getState().entities.currencies.byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId[key];
    if (currencies && !forceUpdate) return null;
    return dispatch(fetchCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId(currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId));
  };
};
export var GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_REQUEST = "GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_REQUEST";
export var GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_SUCCESS = "GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_SUCCESS";
export var GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_FAILURE = "GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_FAILURE";
export var RESET_GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE = "RESET_GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE";

// Fetches currencies from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant = function fetchCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant(currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId, currencyGoalUserBalancesInstant) {
  var queryString = urlSearchParams({
    currencyGoalUserBalancesGoalId: currencyGoalUserBalancesGoalId,
    currencyGoalUserBalancesUserId: currencyGoalUserBalancesUserId,
    currencyGoalUserBalancesInstant: currencyGoalUserBalancesInstant
  });
  return _defineProperty({
    currencyGoalUserBalancesGoalId: currencyGoalUserBalancesGoalId,
    currencyGoalUserBalancesUserId: currencyGoalUserBalancesUserId,
    currencyGoalUserBalancesInstant: currencyGoalUserBalancesInstant
  }, CALL_API, {
    types: [GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_REQUEST, GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_SUCCESS, GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_FAILURE],
    endpoint: "currencies/search/findDistinctByCurrencyGoalUserBalances_Goal_IdAndCurrencyGoalUserBalances_User_IdAndCurrencyGoalUserBalancesInstant".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches currencies by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant = function loadCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant(currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId, currencyGoalUserBalancesInstant, forceUpdate) {
  return function (dispatch, getState) {
    var key = [currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId, currencyGoalUserBalancesInstant].join("-");
    var currencies = getState().entities.currencies.byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant[key];
    if (currencies && !forceUpdate) return null;
    return dispatch(fetchCurrenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant(currencyGoalUserBalancesGoalId, currencyGoalUserBalancesUserId, currencyGoalUserBalancesInstant));
  };
};
export var GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_REQUEST = "GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_REQUEST";
export var GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_SUCCESS = "GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_SUCCESS";
export var GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_FAILURE = "GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_FAILURE";
export var RESET_GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY = "RESET_GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY";

// Fetches currencies from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchCurrenciesByCurrencyBalancesNotEmpty = function fetchCurrenciesByCurrencyBalancesNotEmpty() {
  return _defineProperty({}, CALL_API, {
    types: [GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_REQUEST, GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_SUCCESS, GET_CURRENCIESBYCURRENCYBALANCESNOTEMPTY_FAILURE],
    endpoint: "currencies/search/findAllDistinctByCurrencyBalancesNotEmpty",
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches currencies by id from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadCurrenciesByCurrencyBalancesNotEmpty = function loadCurrenciesByCurrencyBalancesNotEmpty(forceUpdate) {
  return function (dispatch, getState) {
    var call = getState().apiCalls.loadCurrenciesByCurrencyBalancesNotEmpty;
    if (call.fetchSuccessfulAtLeastOnce && !forceUpdate) return null;
    return dispatch(fetchCurrenciesByCurrencyBalancesNotEmpty());
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsGoalIdAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsGoalIdAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsBrokerId, currencyOperationsGoalId, currencyOperationsInvestmentItemId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      currencyOperationsBrokerId: currencyOperationsBrokerId,
      currencyOperationsGoalId: currencyOperationsGoalId,
      currencyOperationsInvestmentItemId: currencyOperationsInvestmentItemId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_Broker_IdAndCurrencyOperations_Goal_IdAndCurrencyOperations_InvestmentItem_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsGoalIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsGoalIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsBrokerId, currencyOperationsGoalId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      currencyOperationsBrokerId: currencyOperationsBrokerId,
      currencyOperationsGoalId: currencyOperationsGoalId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_Broker_IdAndCurrencyOperations_Goal_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsBrokerId, currencyOperationsInvestmentItemId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      currencyOperationsBrokerId: currencyOperationsBrokerId,
      currencyOperationsInvestmentItemId: currencyOperationsInvestmentItemId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_Broker_IdAndCurrencyOperations_InvestmentItem_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsBrokerIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsBrokerId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      currencyOperationsBrokerId: currencyOperationsBrokerId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSBROKERIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_Broker_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsGoalIdAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsGoalIdAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsGoalId, currencyOperationsInvestmentItemId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      currencyOperationsGoalId: currencyOperationsGoalId,
      currencyOperationsInvestmentItemId: currencyOperationsInvestmentItemId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_Goal_IdAndCurrencyOperations_InvestmentItem_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsGoalIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsGoalIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsGoalId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      currencyOperationsGoalId: currencyOperationsGoalId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_Goal_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsInvestmentItemIdAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsInvestmentItemId, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      currencyOperationsInvestmentItemId: currencyOperationsInvestmentItemId,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_InvestmentItem_IdAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches currencies by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadCurrenciesByNameContainingAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull = function loadCurrenciesByNameContainingAndCurrencyOperationsUserIdAndCurrencyOperationsCommittedAtNotNull(page, size, name, currencyOperationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      currencyOperationsUserId: currencyOperationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_CURRENCIESBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "currencies/search/findDistinctByNameContainingAndCurrencyOperations_User_IdAndCurrencyOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var POST_CURRENCY_REQUEST = "POST_CURRENCY_REQUEST";
export var POST_CURRENCY_SUCCESS = "POST_CURRENCY_SUCCESS";
export var POST_CURRENCY_FAILURE = "POST_CURRENCY_FAILURE";
export var RESET_POST_CURRENCY = "RESET_POST_CURRENCY";
export var postCurrency = function postCurrency(currency) {
  return function (dispatch, getState) {
    var endpoint = "currencies";
    if (currency.id) endpoint += "/".concat(currency.id);
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_CURRENCY_REQUEST, POST_CURRENCY_SUCCESS, POST_CURRENCY_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(currency),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostCurrency = function resetPostCurrency() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_CURRENCY
    });
  };
};
export var POST_CURRENCYPICTURE_REQUEST = "POST_CURRENCYPICTURE_REQUEST";
export var POST_CURRENCYPICTURE_SUCCESS = "POST_CURRENCYPICTURE_SUCCESS";
export var POST_CURRENCYPICTURE_FAILURE = "POST_CURRENCYPICTURE_FAILURE";
export var RESET_POST_CURRENCYPICTURE = "RESET_POST_CURRENCYPICTURE";
export var postCurrencyPicture = function postCurrencyPicture(currency, picture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      currency: currency,
      picture: picture
    }, CALL_API, {
      types: [POST_CURRENCYPICTURE_REQUEST, POST_CURRENCYPICTURE_SUCCESS, POST_CURRENCYPICTURE_FAILURE],
      endpoint: "currencies/".concat(currency.id, "/picture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(picture),
        credentials: "same-origin"
      }
    }));
  };
};