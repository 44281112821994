import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var addressApiCalls = {
  loadAddressesByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_ADDRESSESBYUSERID_REQUEST, ActionTypes.GET_ADDRESSESBYUSERID_SUCCESS, ActionTypes.GET_ADDRESSESBYUSERID_FAILURE, ActionTypes.RESET_GET_ADDRESSESBYUSERID]
  })
};
export default addressApiCalls;