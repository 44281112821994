import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var quoteApiCalls = {
  loadQuoteByOperationTypeAndInvestmentItemIdAndCurrencyIdAndBrokerIdAndUserIdAndInvestmentItemAmount: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.operationType, action.investmentItemId, action.currencyId, action.brokerId, action.userId, action.investmentItemAmount].join("-");
    },
    types: [ActionTypes.GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_REQUEST, ActionTypes.GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_SUCCESS, ActionTypes.GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_FAILURE, ActionTypes.RESET_GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT]
  })
};
export default quoteApiCalls;