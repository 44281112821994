function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_REQUEST = "GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_REQUEST";
export var GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_SUCCESS = "GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_SUCCESS";
export var GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_FAILURE = "GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_FAILURE";
export var RESET_GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT = "RESET_GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT";

// Fetches external balance for a particular broker, currency and user.
// Relies on Redux Thunk middleware.
export var loadQuoteByOperationTypeAndInvestmentItemIdAndCurrencyIdAndBrokerIdAndUserIdAndInvestmentItemAmount = function loadQuoteByOperationTypeAndInvestmentItemIdAndCurrencyIdAndBrokerIdAndUserIdAndInvestmentItemAmount(operationType, brokerId, currencyId, investmentItemId, userId, investmentItemAmount) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      operationType: operationType,
      brokerId: brokerId,
      currencyId: currencyId,
      investmentItemId: investmentItemId,
      userId: userId,
      investmentItemAmount: investmentItemAmount
    });
    return dispatch(_defineProperty({
      operationType: operationType,
      brokerId: brokerId,
      currencyId: currencyId,
      investmentItemId: investmentItemId,
      userId: userId,
      investmentItemAmount: investmentItemAmount
    }, CALL_API, {
      types: [GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_REQUEST, GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_SUCCESS, GET_QUOTEBYOPERATIONTYPEANDBROKERIDANDCURRENCYIDANDINESTMENTITEMIDANDUSERIDANDINVESTMENTITEMAMOUNT_FAILURE],
      endpoint: "quotes/fetchByOperationTypeAndInvestmentItemIdAndCurrencyIdAndBrokerIdAndUserIdAndInvestmentItemAmount".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};