function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var postReactionEntities = function postReactionEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byPostIdAndUserId: {},
    byPostIdAndAuthUserId: {},
    byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        return eventListItem.postListItemByPostId && Object.values(eventListItem.postListItemByPostId).forEach(function (postListItem) {
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postReactionsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
          merge(byPostIdAndUserId, postListItem.postReactionByPostIdAndUserId);
        });
      });
      return Object.assign({}, state, {
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_BROKERMENTIONLISTITEMSBYBROKERMENTIONBROKERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_GOALMENTIONLISTITEMSBYGOALMENTIONGOALIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_INVESTMENTITEMMENTIONLISTITEMSBYINVESTMENTITEMIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_USERMENTIONLISTITEMSBYUSERMENTIONUSERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_HASHTAGMENTIONLISTITEMSBYHASHTAGMENTIONHASHTAGID_SUCCESS:
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (mentionListItem) {
        return mentionListItem.postListItemByPostId && Object.values(mentionListItem.postListItemByPostId).forEach(function (postListItem) {
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postReactionsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
          merge(byPostIdAndUserId, postListItem.postReactionByPostIdAndUserId);
        });
      });
      return merge({}, state, {
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_BOOKMARKLISTITEMSBYUSERID_SUCCESS:
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (bookmarkListItem) {
        return bookmarkListItem.postListItemByPostBookmarksId && Object.values(bookmarkListItem.postListItemByPostBookmarksId).forEach(function (postListItem) {
          merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postReactionsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
          merge(byPostIdAndUserId, postListItem.postReactionByPostIdAndUserId);
        });
      });
      return Object.assign({}, state, {
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_POSTLISTITEMSBYPOSTPARENTIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDANDAUTHUSERID_SUCCESS:
    case ActionTypes.GET_POSTLISTITEMSBYPOSTUSERIDNOTANDPOSTPARENTISNULLANDPOSTEVENTISNULL_SUCCESS:
      var byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull = _objectSpread({}, state.byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      action.response.forEach(function (postListItem) {
        merge(byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull, postListItem.postReactionsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull);
        merge(byPostIdAndUserId, postListItem.postReactionByPostIdAndUserId);
      });
      return Object.assign({}, state, {
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.GET_POSTLISTITEMBYPOSTIDANDAUTHUSERID_SUCCESS:
      return merge({}, state, {
        byPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull: action.response.postReactionsByPostIdAndUserFolloweesUserIdAndUserFolloweesConfirmedAtNotNull,
        byPostIdAndUserId: action.response.postReactionByPostIdAndUserId
      });
    case ActionTypes.POST_POSTREACTION_SUCCESS:
      var byPostIdAndAuthUserId = _objectSpread({}, state.byPostIdAndAuthUserId);
      var embedded = action.response._embedded;
      var key = [action.postReaction.postId, action.postReaction.userId].join("-");
      if (!byPostIdAndAuthUserId[key]) byPostIdAndAuthUserId[key] = [];
      byPostIdAndAuthUserId[key].push(embedded);
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      byPostIdAndUserId[key] = embedded;
      return Object.assign({}, state, {
        byPostIdAndAuthUserId: byPostIdAndAuthUserId,
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.DELETE_POSTREACTION_SUCCESS:
      var byPostIdAndAuthUserId = _objectSpread({}, state.byPostIdAndAuthUserId);
      var key = [action.postReaction.post.id, action.postReaction.userId].join("-");
      if (byPostIdAndAuthUserId[key]) byPostIdAndAuthUserId[key] = byPostIdAndAuthUserId[key].filter(function (postReaction) {
        return postReaction.id !== action.postReaction.id;
      });
      var byPostIdAndUserId = _objectSpread({}, state.byPostIdAndUserId);
      delete byPostIdAndUserId[key];
      return Object.assign({}, state, {
        byPostIdAndAuthUserId: byPostIdAndAuthUserId,
        byPostIdAndUserId: byPostIdAndUserId
      });
    case ActionTypes.POST_USERFOLLOWER_SUCCESS:
      var embedded = action.response._embedded;
      var byPostIdAndAuthUserId = _objectSpread({}, state.byPostIdAndAuthUserId);
      Object.keys(byPostIdAndAuthUserId).forEach(function (k) {
        var byAuthUserId = k.split("-")[1];
        if (byAuthUserId !== action.userFollower.userId) return;
        byPostIdAndAuthUserId[k].forEach(function (elem) {
          if (elem.user.username === action.userFollower.followeeUsername) elem.forr = embedded;
        });
      });
      return Object.assign({}, state, {
        byPostIdAndAuthUserId: byPostIdAndAuthUserId
      });
    case ActionTypes.DELETE_USERFOLLOWER_SUCCESS:
      var byPostIdAndAuthUserId = _objectSpread({}, state.byPostIdAndAuthUserId);
      Object.keys(byPostIdAndAuthUserId).forEach(function (k) {
        var byAuthUserId = k.split("-")[1];
        if (byAuthUserId !== action.userFollower.user.email) return;
        byPostIdAndAuthUserId[k].forEach(function (elem) {
          if (elem.user.username === action.userFollower.followee.username) elem.forr = null;
        });
      });
      return Object.assign({}, state, {
        byPostIdAndAuthUserId: byPostIdAndAuthUserId
      });
    case ActionTypes.GET_POSTREACTIONLISTITEMSBYPOSTIDANDAUTHUSERID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (listItem) {
        return merge(byId, listItem.postReactionById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    default:
      return state;
  }
};
export default postReactionEntities;