function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var investmentItemEntities = function investmentItemEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byUrlFriendlyName: {},
    byAvailabilitiesBrokerId: {},
    byAvailabilitiesNotEmpty: [],
    byTypeAndPictureIsNotNull: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_INVESTMENTITEMBYID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      byId[action.id] = action.response;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_BROKERCURRENCYGOALINVESTMENTITEMUSERBALANCESBYGOALIDANDUSERIDANDINSTANT_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.brokerCurrencyGoalInvestmentItemUserBalances.forEach(function (balance) {
        return byId[balance.investmentItem.id] = balance.investmentItem;
      });
      return Object.assign({}, state, {
        byId: byId
      });

    // case ActionTypes.GET_FIRSTRISKPROFILEBYGOALIDANDUSERIDORDERBYCREATEDATDESC_SUCCESS:
    //   var byId = { ...state.byId };
    //   action.response.fractions.forEach(
    //     (fraction) =>
    //       (byId[fraction.investmentItem.id] = fraction.investmentItem)
    //   );
    //   return Object.assign({}, state, { byId });

    // case ActionTypes.POST_RISKPROFILE_SUCCESS:
    //   var byId = { ...state.byId };
    //   action.response._embedded.fractions.forEach(
    //     (fraction) =>
    //       (byId[fraction.investmentItem.id] = fraction.investmentItem)
    //   );
    //   return Object.assign({}, state, { byId });

    case ActionTypes.GET_INVESTMENTITEMSBYAVAILABILITIESBROKERID_SUCCESS:
      var byAvailabilitiesBrokerId = _objectSpread({}, state.byAvailabilitiesBrokerId);
      byAvailabilitiesBrokerId[action.availabilitiesBrokerId] = action.response._embedded.investmentItems;
      return Object.assign({}, state, {
        byAvailabilitiesBrokerId: byAvailabilitiesBrokerId
      });
    case ActionTypes.GET_INVESTMENTITEMS_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.investmentItems.forEach(function (investmentItem) {
        return byId[investmentItem.id] = investmentItem;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_INVESTMENTITEMBYURLFRIENDLYNAME_SUCCESS:
      var byUrlFriendlyName = _objectSpread({}, state.byUrlFriendlyName);
      byUrlFriendlyName[action.urlFriendlyName] = action.response;
      var byId = _objectSpread({}, state.byId);
      byId[action.response.id] = action.response;
      return Object.assign({}, state, {
        byUrlFriendlyName: byUrlFriendlyName,
        byId: byId
      });
    case ActionTypes.POST_INVESTMENTITEM_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var embedded = action.response._embedded;
      byId[embedded.id] = embedded;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_INVESTMENTITEMDESCRIPTION_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      byId[action.investmentItem.id] = action.response._embedded;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_INVESTMENTITEMPICTURE_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var investmentItem = byId[action.investmentItem.id];
      if (investmentItem) {
        var investmentItem = _objectSpread({}, investmentItem);
        investmentItem.picture = action.response._embedded;
        byId[action.investmentItem.id] = investmentItem;
      }
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.investmentItemListItems.forEach(function (item) {
        return merge(byId, item.investmentItemById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_WATCHLISTITEMLISTITEMSBYUSERID_SUCCESS:
      if (!action.response) return state;
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (watchlistItemListItem) {
        return Object.values(watchlistItemListItem.investmentItemListItemByInvestmentItemWatchListItemsId).forEach(function (investmentItemListItem) {
          return merge(byId, investmentItemListItem.investmentItemById);
        });
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_INVESTMENTITEMSVIEWBYAUTHUSERID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.investmentItemListItemsOrderByScoreDesc.forEach(function (investmentItemListItem) {
        return merge(byId, investmentItemListItem.investmentItemById);
      });
      Object.values(action.response.investmentItemListItemsByTypeAndAuthUserId).forEach(function (investmentItemListItems) {
        return investmentItemListItems.forEach(function (investmentItemListItem) {
          return merge(byId, investmentItemListItem.investmentItemById);
        });
      });
      Object.values(action.response.investmentItemListItemsByIndustryIdAndAuthUserId).forEach(function (investmentItemListItems) {
        return investmentItemListItems.forEach(function (investmentItemListItem) {
          return merge(byId, investmentItemListItem.investmentItemById);
        });
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_INVESTMENTITEMVIEWBYINVESTMENTITEMURLFRIENDLYNAME_SUCCESS:
    case ActionTypes.GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS:
    case ActionTypes.GET_OPERATIONFORMBYINVESTMENTITEMIDANDUSERID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      mergeWith(byId, action.response.investmentItemById, function (a, b) {
        return b === null ? a : undefined;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_INVESTMENTPARTYVIEWBYINVESTMENTPARTYIDANDPARTICIPANTUSERID_SUCCESS:
      var byTypeAndPictureIsNotNull = _objectSpread({}, state.byTypeAndPictureIsNotNull);
      merge(byTypeAndPictureIsNotNull, action.response.investmentItemsByTypeAndPictureIsNotNull);
      return Object.assign({}, state, {
        byTypeAndPictureIsNotNull: byTypeAndPictureIsNotNull
      });
    default:
      return state;
  }
};
export default investmentItemEntities;