import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import "moment/locale/es";
import { postUser } from "../actions";
moment.locale("es");
function EnsureAuthenticatedUserTimeZoneIdIsCorrect(props) {
  var dispatch = useDispatch();
  var authUserId = useSelector(function (state) {
    return state.authenticationStatus.userId;
  });
  var authUser = useSelector(function (state) {
    return state.entities.users.byId[authUserId];
  });
  useEffect(function () {
    var timeZone = moment.tz.guess();
    if (timeZone !== authUser.timeZone.name) return;
    authUser.timeZondName = timeZone;
    dispatch(postUser(authUser));
  }, []);
  return props.children;
}
export default EnsureAuthenticatedUserTimeZoneIdIsCorrect;