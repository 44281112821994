export var plainColorStyle = function plainColorStyle(_ref) {
  var color = _ref.color,
    theme = _ref.theme;
  return {
    "& label.Mui-focused": {
      color: theme.palette[color || "primary"].text
    },
    "& .MuiInputBase-input": {
      color: theme.palette[color || "primary"].text
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette[color || "primary"].text
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette[color || "primary"].main
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid ".concat(theme.palette[color || "primary"].main)
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme.palette[color || "primary"].main
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette[color || "primary"].main
      }
    }
  };
};
export var numericFormatLargeStyle = function numericFormatLargeStyle(_ref2) {
  var color = _ref2.color,
    theme = _ref2.theme;
  return {
    "& .MuiInputBase-root": {
      fontSize: "2rem"
    }
  };
};
export var numericFormatSmallStyle = function numericFormatSmallStyle(_ref3) {
  var color = _ref3.color,
    theme = _ref3.theme;
  return {
    "& .MuiInputBase-root": {
      fontSize: "1rem"
    },
    "& .MuiFilledInput-input": {
      paddingTop: "8px"
    }
  };
};