import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setRedirectUrl } from "../actions";
function EnsureLoggedIn(props) {
  var dispatch = useDispatch();
  var _useSelector = useSelector(function (state) {
      return state.authenticationStatus;
    }),
    isLoggedIn = _useSelector.isLoggedIn;
  var navigate = useNavigate();
  var location = useLocation();
  var currentUrl = location.pathname + location.search;
  if (!isLoggedIn) {
    // set the current url/path for future redirection (we use a Redux action)
    // then redirect (we use a React Router method)
    dispatch(setRedirectUrl(currentUrl));
    navigate("/login");
    return;
  }
  return props.children;
}
export default EnsureLoggedIn;