import { combineReducers } from "redux";
import paginate from "./paginate";
import * as ActionTypes from "../../actions";

//Updates the pagination data for different actions.
var pagination = combineReducers({
  loadOperationsByBrokerId: paginate({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerId.toString();
    },
    types: [ActionTypes.GET_OPERATIONSBYBROKERID_REQUEST, ActionTypes.GET_OPERATIONSBYBROKERID_SUCCESS, ActionTypes.GET_OPERATIONSBYBROKERID_FAILURE]
  }),
  loadInvestmentAccountsByBrokerId: paginate({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerId.toString();
    },
    types: [ActionTypes.GET_INVESTMENTACCOUNTSBYBROKERID_REQUEST, ActionTypes.GET_INVESTMENTACCOUNTSBYBROKERID_SUCCESS, ActionTypes.GET_INVESTMENTACCOUNTSBYBROKERID_FAILURE]
  }),
  loadUsersByInvestmentAccountBrokerId: paginate({
    mapActionToKey: function mapActionToKey(action) {
      return action.investmentAccountsBrokerId;
    },
    types: [ActionTypes.GET_USERSBYINVESTMENTACCOUNTBROKERID_REQUEST, ActionTypes.GET_USERSBYINVESTMENTACCOUNTBROKERID_SUCCESS, ActionTypes.GET_USERSBYINVESTMENTACCOUNTBROKERID_FAILURE]
  }),
  loadEmployeesByBrokerId: paginate({
    mapActionToKey: function mapActionToKey(action) {
      return action.brokerId.toString();
    },
    types: [ActionTypes.GET_EMPLOYEESBYBROKERID_REQUEST, ActionTypes.GET_EMPLOYEESBYBROKERID_SUCCESS, ActionTypes.GET_EMPLOYEESBYBROKERID_FAILURE]
  }),
  operations: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_OPERATIONS_REQUEST, ActionTypes.GET_OPERATIONS_SUCCESS, ActionTypes.GET_OPERATIONS_FAILURE]
  }),
  bindTransactions: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BINDOPERATIONS_REQUEST, ActionTypes.GET_BINDOPERATIONS_SUCCESS, ActionTypes.GET_BINDOPERATIONS_FAILURE]
  }),
  users: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_USERS_REQUEST, ActionTypes.GET_USERS_SUCCESS, ActionTypes.GET_USERS_FAILURE]
  }),
  goals: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_GOALS_REQUEST, ActionTypes.GET_GOALS_SUCCESS, ActionTypes.GET_GOALS_FAILURE]
  }),
  goalTypes: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_GOALTYPES_REQUEST, ActionTypes.GET_GOALTYPES_SUCCESS, ActionTypes.GET_GOALTYPES_FAILURE]
  }),
  boosts: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BOOSTS_REQUEST, ActionTypes.GET_BOOSTS_SUCCESS, ActionTypes.GET_BOOSTS_FAILURE]
  }),
  brokers: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BROKERS_REQUEST, ActionTypes.GET_BROKERS_SUCCESS, ActionTypes.GET_BROKERS_FAILURE]
  }),
  prices: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_PRICES_REQUEST, ActionTypes.GET_PRICES_SUCCESS, ActionTypes.GET_PRICES_FAILURE]
  }),
  cafciPrecios: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CAFCIPRECIOS_REQUEST, ActionTypes.GET_CAFCIPRECIOS_SUCCESS, ActionTypes.GET_CAFCIPRECIOS_FAILURE]
  }),
  invertirOnlinePrices: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVERTIRONLINEPRICES_REQUEST, ActionTypes.GET_INVERTIRONLINEPRICES_SUCCESS, ActionTypes.GET_INVERTIRONLINEPRICES_FAILURE]
  }),
  bankHolidays: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BANKHOLIDAYS_REQUEST, ActionTypes.GET_BANKHOLIDAYS_SUCCESS, ActionTypes.GET_BANKHOLIDAYS_FAILURE]
  }),
  balances: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BALANCES_REQUEST, ActionTypes.GET_BALANCES_SUCCESS, ActionTypes.GET_BALANCES_FAILURE]
  }),
  investmentAccounts: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTACCOUNTS_REQUEST, ActionTypes.GET_INVESTMENTACCOUNTS_SUCCESS, ActionTypes.GET_INVESTMENTACCOUNTS_FAILURE]
  }),
  investmentItems: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTITEMS_REQUEST, ActionTypes.GET_INVESTMENTITEMS_SUCCESS, ActionTypes.GET_INVESTMENTITEMS_FAILURE]
  }),
  investmentParties: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTPARTIES_REQUEST, ActionTypes.GET_INVESTMENTPARTIES_SUCCESS, ActionTypes.GET_INVESTMENTPARTIES_FAILURE]
  }),
  riskProfiles: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_RISKPROFILES_REQUEST, ActionTypes.GET_RISKPROFILES_SUCCESS, ActionTypes.GET_RISKPROFILES_FAILURE]
  }),
  events: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_EVENTS_REQUEST, ActionTypes.GET_EVENTS_SUCCESS, ActionTypes.GET_EVENTS_FAILURE]
  }),
  notifications: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_NOTIFICATIONS_REQUEST, ActionTypes.GET_NOTIFICATIONS_SUCCESS, ActionTypes.GET_NOTIFICATIONS_FAILURE]
  }),
  employees: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_EMPLOYEES_REQUEST, ActionTypes.GET_EMPLOYEES_SUCCESS, ActionTypes.GET_EMPLOYEES_FAILURE]
  }),
  posts: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_POSTS_REQUEST, ActionTypes.GET_POSTS_SUCCESS, ActionTypes.GET_POSTS_FAILURE]
  }),
  investmentItemListItemsAndAuthUserId: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_REQUEST, ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS, ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_FAILURE]
  }),
  currencies: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CURRENCIES_REQUEST, ActionTypes.GET_CURRENCIES_SUCCESS, ActionTypes.GET_CURRENCIES_FAILURE]
  }),
  investmentPlans: paginate({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_INVESTMENTPLANS_REQUEST, ActionTypes.GET_INVESTMENTPLANS_SUCCESS, ActionTypes.GET_INVESTMENTPLANS_FAILURE]
  })
});
export default pagination;