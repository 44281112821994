function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";
import { memberStates } from "../../constants";

// Updates an entity cache in response to any action with response.entities.
var currencyEntities = function currencyEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byCode: {},
    byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId: {},
    byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant: {},
    byCurrencyBalancesNotEmpty: [],
    byCurrencyBalancesBrokerId: {},
    byBrokerCurrencyBalancesBrokerId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_CURRENCIES_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.currencies.forEach(function (currency) {
        return byId[currency.id] = currency;
      });
      var byCode = _objectSpread({}, state.byCode);
      action.response._embedded.currencies.forEach(function (currency) {
        return byCode[currency.code] = currency;
      });
      return Object.assign({}, state, {
        byId: byId,
        byCode: byCode
      });
    case ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERID_SUCCESS:
      var byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId = _objectSpread({}, state.byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId);
      var key = [action.currencyGoalUserBalancesGoalId, action.currencyGoalUserBalancesUserId].join("-");
      byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId[key] = action.response._embedded.currencies;
      return Object.assign({}, state, {
        byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId: byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId
      });
    case ActionTypes.GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_SUCCESS:
      var byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId = _objectSpread({}, state.byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId);
      action.response.forEach(function (memberListItem) {
        return merge(byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId, memberListItem.currenciesByCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId);
      });
      return Object.assign({}, state, {
        byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId: byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserId
      });
    case ActionTypes.GET_CURRENCIESBYCURENCYCGOALUSERBALANCESGOALIDANDCURENCYGOALUSERBALANCESUSERIDANDCURENCYGOALUSERBALANCESDATE_SUCCESS:
      var byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant = _objectSpread({}, state.byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant);
      var key = [action.currencyGoalUserBalancesGoalId || "null", action.currencyGoalUserBalancesUserId, action.currencyGoalUserBalancesInstant].join("-");
      byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant[key] = action.response._embedded.currencies;
      return Object.assign({}, state, {
        byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant: byCurrencyGoalUserBalancesGoalIdAndCurrencyGoalUserBalancesUserIdAndCurrencyGoalUserBalancesInstant
      });
    case ActionTypes.GET_DASHBOARD_SUCCESS:
      var byCurrencyBalancesNotEmpty = action.response.currenciesByCurrencyBalancesNotEmpty;
      return merge({}, state, {
        byCurrencyBalancesNotEmpty: byCurrencyBalancesNotEmpty
      });
    case ActionTypes.GET_MYBROKERSDASHBOARDBYBROKERID_SUCCESS:
      var byBrokerCurrencyBalancesBrokerId = action.response.currenciesByBrokerCurrencyBalancesBrokerId;
      return merge({}, state, {
        byBrokerCurrencyBalancesBrokerId: byBrokerCurrencyBalancesBrokerId
      });
    case ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.investmentItemListItems.forEach(function (item) {
        return merge(byId, item.currencyById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_OPERATIONFORMBYINVESTMENTITEMIDANDUSERID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      merge(byId, action.response.currencyById);
      return Object.assign({}, state, {
        byId: byId
      });
    default:
      return state;
  }
};
export default currencyEntities;