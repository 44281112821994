import { combineReducers } from "redux";
import bankHolidayRows from "./bankHolidayRows";
import boostRows from "./boostRows";
import brokerRows from "./brokerRows";
import cafciPrecioRows from "./cafciPrecioRows";
import currencyRows from "./currencyRows";
import employeeRows from "./employeeRows";
import eventRows from "./eventRows";
import goalRows from "./goalRows";
import goalTypeRows from "./goalTypeRows";
import invertirOnlinePriceRows from "./invertirOnlinePriceRows";
import investmentAccountRows from "./investmentAccountRows";
import investmentItemRows from "./investmentItemRows";
import riskProfileRows from "./riskProfileRows";
import myBrokersOperationRows from "./myBrokersOperationRows";
import myBrokersInvestmentAccountRows from "./myBrokersInvestmentAccountRows";
import myBrokersUserRows from "./myBrokersUserRows";
import myBrokersPriceRows from "./myBrokersPriceRows";
import myBrokersEmployeeRows from "./myBrokersEmployeeRows";
import notificationRows from "./notificationRows";
import postRows from "./postRows";
import priceRows from "./priceRows";
import investmentPartyRows from "./investmentPartyRows";
import operationRows from "./operationRows";
import userRows from "./userRows";
var rows = combineReducers({
  bankHolidays: bankHolidayRows,
  brokers: brokerRows,
  boosts: boostRows,
  cafciPrecios: cafciPrecioRows,
  currencies: currencyRows,
  employees: employeeRows,
  events: eventRows,
  goals: goalRows,
  goalTypes: goalTypeRows,
  invertirOnlinePrices: invertirOnlinePriceRows,
  investmentAccounts: investmentAccountRows,
  investmentItems: investmentItemRows,
  riskProfiles: riskProfileRows,
  myBrokersOperations: myBrokersOperationRows,
  myBrokersInvestmentAccounts: myBrokersInvestmentAccountRows,
  myBrokersUsers: myBrokersUserRows,
  myBrokersEmployees: myBrokersEmployeeRows,
  myBrokersPrices: myBrokersPriceRows,
  notifications: notificationRows,
  posts: postRows,
  prices: priceRows,
  investmentParties: investmentPartyRows,
  operations: operationRows,
  users: userRows
});
export default rows;