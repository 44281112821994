import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemsViewApiCalls = {
  loadInvestmentItemsViewByAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.authUserId.toString();
    },
    types: [ActionTypes.GET_INVESTMENTITEMSVIEWBYAUTHUSERID_REQUEST, ActionTypes.GET_INVESTMENTITEMSVIEWBYAUTHUSERID_SUCCESS, ActionTypes.GET_INVESTMENTITEMSVIEWBYAUTHUSERID_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMSVIEWBYAUTHUSERID]
  })
};
export default investmentItemsViewApiCalls;