import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var investmentItemListItemApiCalls = {
  loadInvestmentItemListItemsByAuthUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.authUserId ? action.authUserId.toString() : "null";
    },
    types: [ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_REQUEST, ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS, ActionTypes.GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_FAILURE, ActionTypes.RESET_GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID]
  })
};
export default investmentItemListItemApiCalls;