import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var priceForecastApiCalls = {
  loadPriceForecastsByInvestmentItemIdAndCurrencyIdAndBrokerIdAndInstantBetweenGroupByDateFormat: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.investmentItemId, action.currencyId, action.brokerId, action.fromInstant, action.toInstant, action.dateFormat].join("-");
    },
    types: [ActionTypes.GET_PRICEFORECASTSBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_REQUEST, ActionTypes.GET_PRICEFORECASTSBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_SUCCESS, ActionTypes.GET_PRICEFORECASTSBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT_FAILURE, ActionTypes.RESET_GET_PRICEFORECASTSBYINVESTMENTITEMIDANDCURRENCYIDANDBROKERIDANDINSTANTBETWEENGROUPBYDATEFORMAT]
  })
};
export default priceForecastApiCalls;