import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var bankAccountApiCalls = {
  loadBankAccountsByUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.userId.toString();
    },
    types: [ActionTypes.GET_BANKACCOUNTSBYUSERID_REQUEST, ActionTypes.GET_BANKACCOUNTSBYUSERID_SUCCESS, ActionTypes.GET_BANKACCOUNTSBYUSERID_FAILURE, ActionTypes.RESET_GET_BANKACCOUNTSBYUSERID]
  })
};
export default bankAccountApiCalls;