import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var documentApiCalls = {
  loadDocumentByUserIdAndTypeName: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.documentTypeName].join("-");
    },
    types: [ActionTypes.GET_DOCUMENTBYUSERIDANDTYPENAME_REQUEST, ActionTypes.GET_DOCUMENTBYUSERIDANDTYPENAME_SUCCESS, ActionTypes.GET_DOCUMENTBYUSERIDANDTYPENAME_FAILURE, ActionTypes.RESET_GET_DOCUMENTBYUSERIDANDTYPENAME]
  }),
  postDocument: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.document.typeName;
    },
    types: [ActionTypes.POST_DOCUMENT_REQUEST, ActionTypes.POST_DOCUMENT_SUCCESS, ActionTypes.POST_DOCUMENT_FAILURE, ActionTypes.RESET_POST_DOCUMENT]
  })
};
export default documentApiCalls;