import status from "./status";
import * as ActionTypes from "../../actions";

//Updates the status apiCalls data for different api call.
var investmentPreferenceApiCalls = {
  loadInvestmentPreferenceByUserIdAndGoalId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.userId, action.goalId || "null"].join("-");
    },
    types: [ActionTypes.GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_REQUEST, ActionTypes.GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_SUCCESS, ActionTypes.GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID_FAILURE, ActionTypes.RESET_GET_INVESTMENTPREFERENCEBYUSERIDANDGOALID]
  })
};
export default investmentPreferenceApiCalls;