import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var distributionTabApiCalls = {
  fetchDistributionTabByGoalIdAndUserId: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.goalId || "null", action.userId, action.balaneDate].join("-");
    },
    types: [ActionTypes.GET_DISTRIBBUTIONTABBYGOALIDANDUSERID_REQUEST, ActionTypes.GET_DISTRIBBUTIONTABBYGOALIDANDUSERID_SUCCESS, ActionTypes.GET_DISTRIBBUTIONTABBYGOALIDANDUSERID_FAILURE, ActionTypes.RESET_GET_DISTRIBBUTIONTABBYGOALIDANDUSERID]
  })
};
export default distributionTabApiCalls;