import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var brokerApiCalls = {
  loadBrokers: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BROKERS_REQUEST, ActionTypes.GET_BROKERS_SUCCESS, ActionTypes.GET_BROKERS_FAILURE, ActionTypes.RESET_GET_BROKERS]
  }),
  loadBrokersByAvailabilitiesNotEmpty: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_BROKERSBYAVAILABILITIESNOTEMPTY_REQUEST, ActionTypes.GET_BROKERSBYAVAILABILITIESNOTEMPTY_SUCCESS, ActionTypes.GET_BROKERSBYAVAILABILITIESNOTEMPTY_FAILURE, ActionTypes.RESET_GET_BROKERSBYAVAILABILITIESNOTEMPTY]
  }),
  postBroker: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_BROKER_REQUEST, ActionTypes.POST_BROKER_SUCCESS, ActionTypes.POST_BROKER_FAILURE, ActionTypes.RESET_POST_BROKER]
  })
};
export default brokerApiCalls;