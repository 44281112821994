function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_GOALTYPESBYNAMECONTAINING_REQUEST = "GET_GOALTYPESBYNAMECONTAINING_REQUEST";
export var GET_GOALTYPESBYNAMECONTAINING_SUCCESS = "GET_GOALTYPESBYNAMECONTAINING_SUCCESS";
export var GET_GOALTYPESBYNAMECONTAINING_FAILURE = "GET_GOALTYPESBYNAMECONTAINING_FAILURE";
export var RESET_GET_GOALTYPESBYNAMECONTAINING = "RESET_GET_GOALTYPESBYNAMECONTAINING";

// Fetches goal types by name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadGoalTypesByNameContaining = function loadGoalTypesByNameContaining(page, size, name) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      projection: "public",
      page: page,
      size: size,
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_GOALTYPESBYNAMECONTAINING_REQUEST, GET_GOALTYPESBYNAMECONTAINING_SUCCESS, GET_GOALTYPESBYNAMECONTAINING_FAILURE],
      endpoint: "goalTypes/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALTYPES_REQUEST = "GET_GOALTYPES_REQUEST";
export var GET_GOALTYPES_SUCCESS = "GET_GOALTYPES_SUCCESS";
export var GET_GOALTYPES_FAILURE = "GET_GOALTYPES_FAILURE";
export var loadGoalTypes = function loadGoalTypes(page, size, idFilterValue, nameFilterValue, nameFilterMode, startCreatedAtFilterValue, endCreatedAtFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      idFilterValue: idFilterValue,
      nameFilterValue: nameFilterValue,
      nameFilterMode: nameFilterMode,
      startCreatedAtFilterValue: startCreatedAtFilterValue,
      endCreatedAtFilterValue: endCreatedAtFilterValue
    });
    return dispatch(_defineProperty({
      page: page,
      size: size
    }, CALL_API, {
      types: [GET_GOALTYPES_REQUEST, GET_GOALTYPES_SUCCESS, GET_GOALTYPES_FAILURE],
      endpoint: "goalTypes/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_GOALTYPESBYNAMEIN_REQUEST = "GET_GOALTYPESBYNAMEIN_REQUEST";
export var GET_GOALTYPESBYNAMEIN_SUCCESS = "GET_GOALTYPESBYNAMEIN_SUCCESS";
export var GET_GOALTYPESBYNAMEIN_FAILURE = "GET_GOALTYPESBYNAMEIN_FAILURE";

// Fetches goal types by comma separated names from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchGoalTypesByNameIn = function fetchGoalTypesByNameIn(names) {
  var queryString = urlSearchParams({
    projection: "public",
    names: names
  });
  return _defineProperty({
    names: names
  }, CALL_API, {
    types: [GET_GOALTYPESBYNAMEIN_REQUEST, GET_GOALTYPESBYNAMEIN_SUCCESS, GET_GOALTYPESBYNAMEIN_FAILURE],
    endpoint: "goalTypes/search/findByNameIn".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

//Fetches goal types by comma separated names from Front API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadGoalTypesByNameIn = function loadGoalTypesByNameIn(names, forceUpdate) {
  return function (dispatch, getState) {
    var goalTypes = getState().entities.goalTypes.byNameIn[names];
    if (goalTypes && !forceUpdate) return null;
    return dispatch(fetchGoalTypesByNameIn(names));
  };
};
export var CHECK_GOALTYPES = "CHECK_GOALTYPES";
export var checkGoalType = function checkGoalType(id) {
  return {
    id: id,
    type: CHECK_GOALTYPES
  };
};
export var UNCHECK_GOALTYPES = "UNCHECK_GOALTYPES";
export var uncheckGoalType = function uncheckGoalType(id) {
  return {
    id: id,
    type: UNCHECK_GOALTYPES
  };
};
export var CHECK_ALL_GOALTYPES = "CHECK_ALL_GOALTYPES";
export var checkAllGoalType = function checkAllGoalType() {
  return {
    type: CHECK_ALL_GOALTYPES
  };
};
export var UNCHECK_ALL_GOALTYPES = "UNCHECK_ALL_GOALTYPES";
export var uncheckAllGoalType = function uncheckAllGoalType() {
  return {
    type: UNCHECK_ALL_GOALTYPES
  };
};
export var POST_GOALTYPE_REQUEST = "POST_GOALTYPE_REQUEST";
export var POST_GOALTYPE_SUCCESS = "POST_GOALTYPE_SUCCESS";
export var POST_GOALTYPE_FAILURE = "POST_GOALTYPE_FAILURE";
export var RESET_POST_GOALTYPE = "RESET_POST_GOALTYPE";
export var postGoalType = function postGoalType(goalType) {
  return function (dispatch, getState) {
    var endpoint = "goalTypes";
    if (goalType.id) endpoint += "/".concat(goalType.id);
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_GOALTYPE_REQUEST, POST_GOALTYPE_SUCCESS, POST_GOALTYPE_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(goalType),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostGoalType = function resetPostGoalType() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_GOALTYPE
    });
  };
};
export var POST_GOALTYPEPICTURE_REQUEST = "POST_GOALTYPEPICTURE_REQUEST";
export var POST_GOALTYPEPICTURE_SUCCESS = "POST_GOALTYPEPICTURE_SUCCESS";
export var POST_GOALTYPEPICTURE_FAILURE = "POST_GOALTYPEPICTURE_FAILURE";
export var RESET_POST_GOALTYPEPICTURE = "RESET_POST_GOALTYPEPICTURE";
export var postGoalTypePicture = function postGoalTypePicture(goalType, picture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      goalType: goalType,
      picture: picture
    }, CALL_API, {
      types: [POST_GOALTYPEPICTURE_REQUEST, POST_GOALTYPEPICTURE_SUCCESS, POST_GOALTYPEPICTURE_FAILURE],
      endpoint: "goalTypes/".concat(goalType.id, "/picture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(picture),
        credentials: "same-origin"
      }
    }));
  };
};
export var DELETE_GOALTYPES_REQUEST = "DELETE_GOALTYPES_REQUEST";
export var DELETE_GOALTYPES_SUCCESS = "DELETE_GOALTYPES_SUCCESS";
export var DELETE_GOALTYPES_FAILURE = "DELETE_GOALTYPES_FAILURE";
export var RESET_DELETE_GOALTYPE = "RESET_DELETE_GOALTYPE";
export var deleteGoalTypes = function deleteGoalTypes(goalTypes) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({}, CALL_API, {
      types: [DELETE_GOALTYPES_REQUEST, DELETE_GOALTYPES_SUCCESS, DELETE_GOALTYPES_FAILURE],
      endpoint: "goalTypes",
      init: {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(goalTypes),
        credentials: "same-origin"
      }
    }));
  };
};