function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { RESET_POST_BANKHOLIDAY } from "./bankHolidayActions";
export var POST_VALIDATEDOCUMENT_REQUEST = "POST_VALIDATEDOCUMENT_REQUEST";
export var POST_VALIDATEDOCUMENT_SUCCESS = "POST_VALIDATEDOCUMENT_SUCCESS";
export var POST_VALIDATEDOCUMENT_FAILURE = "POSTT_VALIDATEDOCUMENT_FAILURE";
export var RESET_POST_VALIDATEDOCUMENT = "RESET_POST_VALIDATEDOCUMENT";
export var postValidateDocument = function postValidateDocument(userId, documentTypeName, file) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("userId", new Blob([userId], {
      type: "application/json"
    }));
    body.append("documentTypeName", documentTypeName);
    body.append("file", file);
    return dispatch(_defineProperty({
      userId: userId,
      documentTypeName: documentTypeName
    }, CALL_API, {
      types: [POST_VALIDATEDOCUMENT_REQUEST, POST_VALIDATEDOCUMENT_SUCCESS, POST_VALIDATEDOCUMENT_FAILURE],
      endpoint: "validateDocument",
      init: {
        method: "POST",
        body: body
      }
    }));
  };
};
export var resetPostValidateDocument = function resetPostValidateDocument() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_VALIDATEDOCUMENT
    });
  };
};