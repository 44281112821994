function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_REQUEST = "GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_REQUEST";
export var GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS = "GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS";
export var GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_FAILURE = "GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_FAILURE";
export var RESET_GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID = "RESET_GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID";
export var loadInvestmentItemListItemsByAuthUserId = function loadInvestmentItemListItemsByAuthUserId(page, size, timeZoneName, atleastOneAvailabilityBuyEnabledFilterValue, nameFilterValue, typeFilterValue, industryNameFilterValue, availabilitiesBrokerNameFilterValue, cafciClaseCurrencyNameFilterValue, cafciClaseFundHorizonNameFilterValue, cafciClaseFundManagerNameFilterValue, invertirOnlineTitleCurrencyNameFilterValue, authUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "score,desc",
      timeZoneName: timeZoneName,
      atleastOneAvailabilityBuyEnabledFilterValue: atleastOneAvailabilityBuyEnabledFilterValue,
      nameFilterValue: nameFilterValue,
      typeFilterValue: typeFilterValue,
      industryNameFilterValue: industryNameFilterValue,
      availabilitiesBrokerNameFilterValue: availabilitiesBrokerNameFilterValue,
      cafciClaseFundTypeNameFilterValue: "Abierto",
      cafciClaseCurrencyNameFilterValue: cafciClaseCurrencyNameFilterValue,
      cafciClaseFundHorizonNameFilterValue: cafciClaseFundHorizonNameFilterValue,
      cafciClaseFundManagerNameFilterValue: cafciClaseFundManagerNameFilterValue,
      invertirOnlineTitleCurrencyNameFilterValue: invertirOnlineTitleCurrencyNameFilterValue,
      authUserId: authUserId
    });
    return dispatch(_defineProperty({
      timeZoneName: timeZoneName,
      atleastOneAvailabilityBuyEnabledFilterValue: atleastOneAvailabilityBuyEnabledFilterValue,
      nameFilterValue: nameFilterValue,
      typeFilterValue: typeFilterValue,
      industryNameFilterValue: industryNameFilterValue,
      availabilitiesBrokerNameFilterValue: availabilitiesBrokerNameFilterValue,
      cafciClaseCurrencyNameFilterValue: cafciClaseCurrencyNameFilterValue,
      cafciClaseFundHorizonNameFilterValue: cafciClaseFundHorizonNameFilterValue,
      cafciClaseFundManagerNameFilterValue: cafciClaseFundManagerNameFilterValue,
      invertirOnlineTitleCurrencyNameFilterValue: invertirOnlineTitleCurrencyNameFilterValue,
      authUserId: authUserId
    }, CALL_API, {
      types: [GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_REQUEST, GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_SUCCESS, GET_INVESTMENTITEMLISTITEMSBYAUTHUSERID_FAILURE],
      endpoint: "investmentItemListItems/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};