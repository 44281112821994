import { createTheme } from "@mui/material/styles";
import { pink, yellow, red, blue, green } from "@mui/material/colors";
import { brandPrimary, brandPrimaryFocus, brandSecondary, brandSecondaryFocus } from "./constants";

// custom theme
var _createTheme = createTheme(),
  palette = _createTheme.palette;
var theme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  },
  palette: {
    pinky: palette.augmentColor({
      color: pink
    }),
    summer: palette.augmentColor({
      color: yellow
    }),
    primary: {
      main: brandPrimary,
      focus: brandPrimaryFocus,
      dark: brandPrimaryFocus,
      contrastText: "#ffffff",
      text: "#212529"
    },
    secondary: {
      main: brandSecondary,
      focus: brandSecondaryFocus,
      contrastText: "#ffffff"
    },
    success: palette.augmentColor({
      color: green
    }),
    warning: palette.augmentColor({
      color: yellow
    }),
    danger: palette.augmentColor({
      color: red
    }),
    link: palette.augmentColor({
      color: blue
    }),
    light: {
      main: "#ffffff",
      focus: "#ffffff",
      dark: "#ffffff",
      contrastText: "#212529"
    },
    dark: {
      main: "#ffffff",
      focus: "#ffffff",
      dark: "#ffffff",
      contrastText: "#ffffff",
      text: "#212529"
    },
    grey: {
      main: "rgba(233, 233, 233, 0.1)",
      focus: "rgba(233, 233, 233, 0.1)",
      dark: "rgba(233, 233, 233, 0.1)",
      contrastText: "rgba(233, 233, 233, 0.1)",
      text: "rgba(233, 233, 233, 0.1)"
    },
    white: {
      main: "#ffffff",
      focus: "#ffffff",
      dark: "#ffffff",
      contrastText: "#ffffff",
      text: "#ffffff"
    },
    mode: "dark",
    type: "dark",
    background: {
      default: "#000000",
      paper: "rgb(22, 24, 28)"
    },
    mixins: {
      toolbar: {
        minHeight: 64
      }
    }
  }
});
export default theme;