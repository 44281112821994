function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BANKACCOUNTSBYUSERID_REQUEST = "GET_BANKACCOUNTSBYUSERID_REQUEST";
export var GET_BANKACCOUNTSBYUSERID_SUCCESS = "GET_BANKACCOUNTSBYUSERID_SUCCESS";
export var GET_BANKACCOUNTSBYUSERID_FAILURE = "GET_BANKACCOUNTSBYUSERID_FAILURE";
export var RESET_GET_BANKACCOUNTSBYUSERID = "RESET_GET_BANKACCOUNTSBYUSERID";

// Fetches an bankAccount for a particular user from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBankAccountByUserId = function fetchBankAccountByUserId(userId) {
  var queryString = urlSearchParams({
    projection: "full",
    userId: userId
  });
  return _defineProperty({
    userId: userId
  }, CALL_API, {
    types: [GET_BANKACCOUNTSBYUSERID_REQUEST, GET_BANKACCOUNTSBYUSERID_SUCCESS, GET_BANKACCOUNTSBYUSERID_FAILURE],
    endpoint: "bankAccounts/search/findByUser_Id".concat(queryString),
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches an bankAccount for a particular user unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBankAccountsByUserId = function loadBankAccountsByUserId(userId, forceUpdate) {
  return function (dispatch, getState) {
    var bankAccount = getState().entities.bankAccounts.byUserId[userId];
    if (bankAccount && !forceUpdate) return null;
    return dispatch(fetchBankAccountByUserId(userId));
  };
};