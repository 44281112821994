import * as ActionTypes from "../../actions";
import status from "./status";

//Updates the status apiCalls data for different api call.
var userApiCalls = {
  loadUserById: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.id.toString();
    },
    types: [ActionTypes.GET_USERBYID_REQUEST, ActionTypes.GET_USERBYID_SUCCESS, ActionTypes.GET_USERBYID_FAILURE, ActionTypes.RESET_GET_USERBYID
    // ActionTypes.POST_USER_SUCCESS,
    // ActionTypes.POST_DOCUMENT_SUCCESS
    // TODO https://frontinversiones.atlassian.net/browse/FBV-377 Implement a cascade mechanism for chained actions
    ]
  }),

  loadUserByToken: status({
    mapActionToKey: function mapActionToKey(action) {
      return action.token;
    },
    types: [ActionTypes.GET_USERBYTOKEN_REQUEST, ActionTypes.GET_USERBYTOKEN_SUCCESS, ActionTypes.GET_USERBYTOKEN_FAILURE, ActionTypes.RESET_GET_USERBYTOKEN]
  }),
  postUser: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_USER_REQUEST, ActionTypes.POST_USER_SUCCESS, ActionTypes.POST_USER_FAILURE, ActionTypes.RESET_POST_USER]
  }),
  postUserProfilePicture: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_USERPROFILEPICTURE_REQUEST, ActionTypes.POST_USERPROFILEPICTURE_SUCCESS, ActionTypes.POST_USERPROFILEPICTURE_FAILURE, ActionTypes.RESET_POST_USERPROFILEPICTURE]
  }),
  postUserAddresses: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_USERADDRESSES_REQUEST, ActionTypes.POST_USERADDRESSES_SUCCESS, ActionTypes.POST_USERADDRESSES_FAILURE, ActionTypes.RESET_POST_USERADDRESSES]
  }),
  postUserBankAccounts: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_USERBANKACCOUNTS_REQUEST, ActionTypes.POST_USERBANKACCOUNTS_SUCCESS, ActionTypes.POST_USERBANKACCOUNTS_FAILURE, ActionTypes.RESET_POST_USERBANKACCOUNTS]
  }),
  postUserExclusionPreferences: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.POST_USEREXCLUSIONPREFERENCES_REQUEST, ActionTypes.POST_USEREXCLUSIONPREFERENCES_SUCCESS, ActionTypes.POST_USEREXCLUSIONPREFERENCES_FAILURE, ActionTypes.RESET_POST_USEREXCLUSIONPREFERENCES]
  }),
  requestDeleteUser: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_REQUESTDELETEUSER_REQUEST, ActionTypes.GET_REQUESTDELETEUSER_SUCCESS, ActionTypes.GET_REQUESTDELETEUSER_FAILURE, ActionTypes.RESET_GET_REQUESTDELETEUSER]
  }),
  confirmDeleteUser: status({
    mapActionToKey: function mapActionToKey() {
      return "undefined";
    },
    types: [ActionTypes.GET_CONFIRMDELETEUSER_REQUEST, ActionTypes.GET_CONFIRMDELETEUSER_SUCCESS, ActionTypes.GET_CONFIRMDELETEUSER_FAILURE, ActionTypes.RESET_GET_CONFIRMDELETEUSER]
  }),
  postBlockUser: status({
    mapActionToKey: function mapActionToKey(action) {
      return [action.blockerId, action.blockeeId].join("-");
    },
    types: [ActionTypes.POST_BLOCKUSER_REQUEST, ActionTypes.POST_BLOCKUSER_SUCCESS, ActionTypes.POST_BLOCKUSER_FAILURE, ActionTypes.RESET_POST_BLOCKUSER]
  })
};
export default userApiCalls;