function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_REQUEST = "GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_FAILURE = "GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID = "RESET_GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID";

// Fetches brokers by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndPricesInvestmentItemIdAndPricesCurrencyId = function loadBrokersByNameContainingAndPricesInvestmentItemIdAndPricesCurrencyId(page, size, name, pricesInvestmentItemId, pricesCurrencyId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      pricesInvestmentItemId: pricesInvestmentItemId,
      pricesCurrencyId: pricesCurrencyId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_REQUEST, GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_SUCCESS, GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMIDANDPRICESCURRENCYID_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndPrices_InvestmentItem_IdAndPrices_Currency_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_REQUEST = "GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_FAILURE = "GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID = "RESET_GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID";

// Fetches brokers by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndPricesInvestmentItemId = function loadBrokersByNameContainingAndPricesInvestmentItemId(page, size, name, pricesInvestmentItemId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      pricesInvestmentItemId: pricesInvestmentItemId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_REQUEST, GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_SUCCESS, GET_BROKERSBYNAMECONTAININGANDPRICESINVESTMENTITEMID_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndPrices_InvestmentItem_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_REQUEST = "GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_FAILURE = "GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID = "RESET_GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID";

// Fetches brokers by code containing or name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndPricesCurrencyId = function loadBrokersByNameContainingAndPricesCurrencyId(page, size, name, pricesCurrencyId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "full",
      name: name,
      pricesCurrencyId: pricesCurrencyId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_REQUEST, GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_SUCCESS, GET_BROKERSBYNAMECONTAININGANDPRICESCURRENCYID_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndPrices_Currency_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAINING_REQUEST = "GET_BROKERSBYNAMECONTAINING_REQUEST";
export var GET_BROKERSBYNAMECONTAINING_SUCCESS = "GET_BROKERSBYNAMECONTAINING_SUCCESS";
export var GET_BROKERSBYNAMECONTAINING_FAILURE = "GET_BROKERSBYNAMECONTAINING_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAINING = "RESET_GET_BROKERSBYNAMECONTAINING";

// Fetches brokers by name containing from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContaining = function loadBrokersByNameContaining(page, size, name) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAINING_REQUEST, GET_BROKERSBYNAMECONTAINING_SUCCESS, GET_BROKERSBYNAMECONTAINING_FAILURE],
      endpoint: "brokers/search/findByNameContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERBYID_REQUEST = "GET_BROKERBYID_REQUEST";
export var GET_BROKERBYID_SUCCESS = "GET_BROKERBYID_SUCCESS";
export var GET_BROKERBYID_FAILURE = "GET_BROKERBYID_FAILURE";
export var RESET_GET_BROKERBYID = "RESET_GET_BROKERBYID";

// Fetches broker by id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokerById = function loadBrokerById(id) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      id: id
    }, CALL_API, {
      types: [GET_BROKERBYID_REQUEST, GET_BROKERBYID_SUCCESS, GET_BROKERBYID_FAILURE],
      endpoint: "brokers/".concat(id),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERS_REQUEST = "GET_BROKERS_REQUEST";
export var GET_BROKERS_SUCCESS = "GET_BROKERS_SUCCESS";
export var GET_BROKERS_FAILURE = "GET_BROKERS_FAILURE";
export var RESET_GET_BROKERS = "RESET_GET_BROKERS";
export var loadBrokers = function loadBrokers(page, size, idFilterValue, nameFilterValue, startDateFilterValue, endDateFilterValue) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      sort: "createdAt,desc",
      idFilterValue: idFilterValue,
      nameFilterValue: nameFilterValue,
      startDateFilterValue: startDateFilterValue,
      endDateFilterValue: endDateFilterValue
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERS_REQUEST, GET_BROKERS_SUCCESS, GET_BROKERS_FAILURE],
      endpoint: "brokers/search/findAll".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYAVAILABILITIESNOTEMPTY_REQUEST = "GET_BROKERSBYAVAILABILITIESNOTEMPTY_REQUEST";
export var GET_BROKERSBYAVAILABILITIESNOTEMPTY_SUCCESS = "GET_BROKERSBYAVAILABILITIESNOTEMPTY_SUCCESS";
export var GET_BROKERSBYAVAILABILITIESNOTEMPTY_FAILURE = "GET_BROKERSBYAVAILABILITIESNOTEMPTY_FAILURE";
export var RESET_GET_BROKERSBYAVAILABILITIESNOTEMPTY = "RESET_GET_BROKERSBYAVAILABILITIESNOTEMPTY";

// Fetches all brokers that have brokers from Front API.
// Relies on the custom API middleware defined in ../middleware/api.js.
var fetchBrokersByAvailabilitiesNotEmpty = function fetchBrokersByAvailabilitiesNotEmpty() {
  return _defineProperty({}, CALL_API, {
    types: [GET_BROKERSBYAVAILABILITIESNOTEMPTY_REQUEST, GET_BROKERSBYAVAILABILITIESNOTEMPTY_SUCCESS, GET_BROKERSBYAVAILABILITIESNOTEMPTY_FAILURE],
    endpoint: "brokers/search/findDistinctByInvestmentItemAvailabilitiesNotEmpty?projection=withAvailabilitiesCount",
    init: {
      credentials: "same-origin"
    }
  });
};

// Fetches all brokers that have brokers unless it is cached.
// Relies on Redux Thunk middleware.
export var loadBrokersByAvailabilitiesNotEmpty = function loadBrokersByAvailabilitiesNotEmpty() {
  return function (dispatch, getState) {
    var brokers = getState().entities.brokers.byAvailabilitiesNotEmpty;
    if (brokers.length > 0) return null;
    return dispatch(fetchBrokersByAvailabilitiesNotEmpty());
  };
};
export var POST_BROKER_REQUEST = "POST_BROKER_REQUEST";
export var POST_BROKER_SUCCESS = "POST_BROKER_SUCCESS";
export var POST_BROKER_FAILURE = "POST_BROKER_FAILURE";
export var RESET_POST_BROKER = "RESET_POST_BROKER";
export var postBroker = function postBroker(broker) {
  return function (dispatch, getState) {
    var endpoint = "brokers";
    if (broker.id) endpoint += "/".concat(broker.id);
    return dispatch(_defineProperty({}, CALL_API, {
      types: [POST_BROKER_REQUEST, POST_BROKER_SUCCESS, POST_BROKER_FAILURE],
      endpoint: endpoint,
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(broker),
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostBroker = function resetPostBroker() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_BROKER
    });
  };
};
export var POST_BROKERDESCRIPTION_REQUEST = "POST_BROKERDESCRIPTION_REQUEST";
export var POST_BROKERDESCRIPTION_SUCCESS = "POST_BROKERDESCRIPTION_SUCCESS";
export var POST_BROKERDESCRIPTION_FAILURE = "POST_BROKERDESCRIPTION_FAILURE";
export var RESET_POST_BROKERDESCRIPTION = "RESET_POST_BROKERDESCRIPTION";
export var postBrokerDescription = function postBrokerDescription(broker, description) {
  return function (dispatch, getState) {
    var body = new FormData();
    body.append("description", description);
    return dispatch(_defineProperty({
      broker: broker,
      description: description
    }, CALL_API, {
      types: [POST_BROKERDESCRIPTION_REQUEST, POST_BROKERDESCRIPTION_SUCCESS, POST_BROKERDESCRIPTION_FAILURE],
      endpoint: "brokers/".concat(broker.id, "/description"),
      init: {
        method: "POST",
        body: body,
        credentials: "same-origin"
      }
    }));
  };
};
export var resetPostBrokerDescription = function resetPostBrokerDescription() {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_POST_BROKERDESCRIPTION
    });
  };
};
export var POST_BROKERPICTURE_REQUEST = "POST_BROKERPICTURE_REQUEST";
export var POST_BROKERPICTURE_SUCCESS = "POST_BROKERPICTURE_SUCCESS";
export var POST_BROKERPICTURE_FAILURE = "POST_BROKERPICTURE_FAILURE";
export var RESET_POST_BROKERPICTURE = "RESET_POST_BROKERPICTURE";
export var postBrokerPicture = function postBrokerPicture(broker, picture) {
  return function (dispatch, getState) {
    return dispatch(_defineProperty({
      broker: broker,
      picture: picture
    }, CALL_API, {
      types: [POST_BROKERPICTURE_REQUEST, POST_BROKERPICTURE_SUCCESS, POST_BROKERPICTURE_FAILURE],
      endpoint: "brokers/".concat(broker.id, "/picture"),
      init: {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(picture),
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsGoalId, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsGoalId: operationsGoalId,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_Goal_IdAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsGoalId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsGoalId: operationsGoalId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_Goal_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsCurrencyIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsCurrencyId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsCurrencyId: operationsCurrencyId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSCURRENCYIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_Currency_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsGoalIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsGoalIdAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsGoalId, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsGoalId: operationsGoalId,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_Goal_IdAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsGoalIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsGoalId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsGoalId: operationsGoalId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSGOALIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_Goal_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsInvestmentItemIdAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsInvestmentItemId, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsInvestmentItemId: operationsInvestmentItemId,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONSINVESTMENTITEMIDANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_InvestmentItem_IdAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL";

// Fetches brokers by name or type name containing and user id from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsUserIdAndOperationsCommittedAtNotNull = function loadBrokersByNameContainingAndOperationsUserIdAndOperationsCommittedAtNotNull(page, size, name, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERIDANDOPERATIONSCOMMITTEDATNOTNULL_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_User_IdAndOperationsCommittedAtNotNull".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_REQUEST = "GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_REQUEST";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_SUCCESS = "GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_SUCCESS";
export var GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_FAILURE = "GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_FAILURE";
export var RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID = "RESET_GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID";

// Fetches brokers by name containing and operations with user email from Front API.
// Relies on Redux Thunk middleware.
export var loadBrokersByNameContainingAndOperationsUserId = function loadBrokersByNameContainingAndOperationsUserId(page, size, name, operationsUserId) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      projection: "public",
      name: name,
      operationsUserId: operationsUserId
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_REQUEST, GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_SUCCESS, GET_BROKERSBYNAMECONTAININGANDOPERATIONUSERID_FAILURE],
      endpoint: "brokers/search/findDistinctByNameContainingAndOperations_User_Id".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};