function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var GET_CAFCIMONEDASBYNOMBRECONTAINING_REQUEST = "GET_CAFCIMONEDASBYNOMBRECONTAINING_REQUEST";
export var GET_CAFCIMONEDASBYNOMBRECONTAINING_SUCCESS = "GET_CAFCIMONEDASBYNOMBRECONTAINING_SUCCESS";
export var GET_CAFCIMONEDASBYNOMBRECONTAINING_FAILURE = "GET_CAFCIMONEDASBYNOMBRECONTAINING_FAILURE";

//Fetches all CAFCI currencies from Front CAFCI API unless it is cached.
//Relies on Redux Thunk middleware.
export var loadCafciMonedasByNombreContaining = function loadCafciMonedasByNombreContaining(page, size, nombre) {
  return function (dispatch, getState) {
    var queryString = urlSearchParams({
      page: page,
      size: size,
      nombre: nombre
    });
    return dispatch(_defineProperty({}, CALL_API, {
      types: [GET_CAFCIMONEDASBYNOMBRECONTAINING_REQUEST, GET_CAFCIMONEDASBYNOMBRECONTAINING_SUCCESS, GET_CAFCIMONEDASBYNOMBRECONTAINING_FAILURE],
      endpoint: "cafciMonedas/search/findByNombreContaining".concat(queryString),
      init: {
        credentials: "same-origin"
      }
    }));
  };
};