import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEN from "./en.json";
import translationsEN_GB from "./en-GB.json";
import translationsES_AR from "./es-AR.json";
import translationsES_CL from "./es-CL.json";
var resources = {
  en: {
    translation: translationsEN
  },
  "en-GB": {
    translation: translationsEN_GB
  },
  "es-AR": {
    translation: translationsES_AR
  },
  "es-CL": {
    translation: translationsES_CL
  }
};
i18n.use(initReactI18next) // passes i18n down to react-i18next
.use(LanguageDetector).init({
  resources: resources,
  fallbackLng: "en",
  // default language
  keySeparator: false,
  // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;